import axios from "axios";

export const API_SERVER = "https://salesdaily.hoplong.com/api";
export const API_SERVER_ERP = "https://api.hoplong.com/api/v1";
export const API_SERVER_GIGA = "https://testserver.gigadigital.vn/v1";
// export const API_SERVER_GIGA = 'http://127.0.0.1:8001/v1'
export const API_SERVER_GIGAVN = "https://demo.giga.vn/v1";
export const API_SERVER_GHN = "https://online-gateway.ghn.vn/shiip/public-api";

export const API_LOCAL = "https://gateway.hoplong.com/giga_daily/api";

const authHeader = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const axiosIntance = axios.create({
  headers: authHeader(),
});
