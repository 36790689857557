import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

//image xanh
import ImgKichHoat from "../../assets/image/action/h-kich-hoat-1.png";

import ImgHuongDan from "../../assets/image/action/h-huong-dan-1.png";
import ImgTraCuu from "../../assets/image/action/h-tra-cuu-1.png";

import ImgYeuCau from "../../assets/image/action/h-yeu-cau-1.png";
import IconSearch from "../../assets/image/icon_search.png";
//image trắng
import ImgKichHoatWhite from "../../assets/image/action/h-kich-hoat-at.png";
import ImgHuongDanWhite from "../../assets/image/action/h-huong-dan-at.png";
import ImgTraCuuWhite from "../../assets/image/action/h-tra-cuu-at.png";
import ImgYeuCauWhite from "../../assets/image/action/h-yeu-cau-at.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getListProductEcovacs,
  getNewsEcovacsByCreateAt,
} from "../../redux/category/action";

const CheckBaoHanh = () => {
  const dispatch = useDispatch();

  const listNewsEcovacs = useSelector(
    (store) => store.categories.listNewsEcovacs
  );
  const listProductEcovacs = useSelector(
    (store) => store.categories.listProductEcovacs
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [modalYoutube, setModalYoutube] = useState(false);
  const [showVideo, setShowVideo] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [shouldCloseDropdown, setShouldCloseDropdown] = useState(true);

  useEffect(() => {
    dispatch(getListProductEcovacs());
  }, []);

  useEffect(() => {
    dispatch(getNewsEcovacsByCreateAt());
  }, []);

  const handleInputClick = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (shouldCloseDropdown) {
        setShowDropdown(false);
      }
    }, 200);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const removeDiacritics = (text) => {
    return text
      .normalize("NFD") // Chuyển đổi chữ có dấu sang chữ không dấu
      .replace(/[\u0300-\u036f]/g, ""); // Xóa bỏ các dấu
  };

  const filteredNews = listNewsEcovacs.filter((item) => {
    // Lọc danh sách dựa trên giá trị tìm kiếm, so sánh cả chữ có dấu và chữ không dấu
    const titleWithoutDiacritics = removeDiacritics(item.title.toLowerCase());
    const searchValueWithoutDiacritics = removeDiacritics(
      searchValue.toLowerCase()
    );
    return titleWithoutDiacritics.includes(searchValueWithoutDiacritics);
  });
  return (
    <div className="giga-electric">
      <div className="bg-white info">
        <div className="header position-relative">
          <div className="d-block justify-content-center text-banner">
            <ScrollAnimation
              animateIn="bounceInLeft"
              animateOut="bounceInRight"
              duration={1.5}
              delay={0}
              animateOnce={true}
            >
              <h1 className="text-black text-center fw-bold">
                CHÀO MỪNG BẠN ĐẾN VỚI DỊCH VỤ HỖ TRỢ VÀ BẢO HÀNH CỦA ECOVACS
                CARE
              </h1>
              <p className="text-center fs-20">
                Chúng tôi mới ra mắt dịch vụ{" "}
                <Link to={""} className="text-primary">
                  Premium Care{" "}
                </Link>{" "}
                cho người dùng Ecovacs tại Việt Nam
              </p>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="bounceInRight"
              animateOut="bounceInLeft"
              duration={1.5}
              delay={0}
              animateOnce={true}
            >
              <div className="question-search mt-4">
                <div className="box-search d-flex">
                  <input
                    className="form-control p-0"
                    type={"search"}
                    placeholder="Tìm kiếm"
                    onClick={handleInputClick}
                    onBlur={handleBlur}
                    onChange={handleSearchChange}
                    value={searchValue}
                  />
                  <button
                    type="button"
                    className="search-icon border-0 bg-transparent"
                  >
                    <img src={IconSearch} />
                  </button>
                </div>

                {showDropdown && (
                  <div
                    className={`search-content ${showDropdown ? "active" : ""}`}
                  >
                    {filteredNews.map((item) => (
                      <Link
                        to={`/tin-tuc/${item._id}`}
                        key={item.id}
                        onClick={() => setShouldCloseDropdown(true)}
                        className="name-content w-100"
                      >
                        {item.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="container">
          <div className="list-edit py-2">
            <div className="">
              <Link to={""} className="text-primary"></Link>
            </div>
          </div>

          <div className="list-items-button">
            <h2 className="text-center w-100 fw-bold text-capitalize mt-4 mb-4">
              HỖ TRỢ CỦA ECOVACS
            </h2>

            <div className="list-items-button mb-5">
              <Link to={"/kich-hoat-bao-hanh"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgKichHoat} />
                  <img src={ImgKichHoatWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    KÍCH HOẠT <br />
                    <span className="font-normal f-16"> BẢO HÀNH </span>
                  </p>
                </div>
              </Link>
              <Link to={"/tra-cuu-bao-hanh"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgYeuCau} />
                  <img src={ImgYeuCauWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    KIỂM TRA THỜI HẠN <br />
                    <span className="font-normal f-16"> BẢO HÀNH </span>
                  </p>
                </div>
              </Link>
              <Link to={"/gui-yeu-cau"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgYeuCau} />
                  <img src={ImgYeuCauWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    KIỂM TRA TRUNG TÂM <br />
                    <span className="font-normal f-16"> BẢO HÀNH </span>
                  </p>
                </div>
              </Link>
              <Link to={"/product-selector"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgHuongDan} />
                  <img src={ImgHuongDanWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    MUA THÊM <br />
                    <span className="font-normal f-16"> PHỤ KIỆN </span>
                  </p>
                </div>
              </Link>
              <Link to={"/gui-yeu-cau"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgHuongDan} />
                  <img src={ImgHuongDanWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    TẠO YÊU CẦU <br />{" "}
                    <span className="font-normal f-16">BẢO HÀNH</span>
                  </p>
                </div>
              </Link>
              <Link to={"/tra-cuu"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgTraCuu} />
                  <img src={ImgTraCuuWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    TRA CỨU YÊU CẦU <br />{" "}
                    <span className="font-normal f-16"> BẢO HÀNH </span>
                  </p>
                </div>
              </Link>
              <Link to={"/chinh-sach-bao-hanh"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgHuongDan} />
                  <img src={ImgHuongDanWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    HƯỚNG DẪN <br />
                    <span className="font-normal f-16"> SỬ DỤNG </span>
                  </p>
                </div>
              </Link>{" "}
              <Link to={"/chinh-sach-bao-hanh"} className="item-action d-table">
                <div className="box-content d-table-cell align-middle">
                  <img src={ImgHuongDan} />
                  <img src={ImgHuongDanWhite} className="img-2" />
                  <p className="text-black fw-bold mt-4 f-16">
                    CÂU HỎI <br />{" "}
                    <span className="font-normal f-16"> THƯỜNG GẶP </span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
          {/* <div className="list-items-button">
            <h2
              className="text-center w-100 fw-bold text-capitalize"
              style={{ marginBottom: "30px" }}
            >
              TIN TỨC & THÔNG BÁO
            </h2>
            <div className="list-items-button mb-5">
              <Link
                className="item-action d-table"
                style={{ backgroundColor: "white" }}
              >
                <div className="card_blog">
                  <div className="card-header">
                    <img
                      src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg"
                      alt="rover"
                    />
                  </div>
                  <span className="tag-blog">Câu hỏi thường gặp</span>
                  <div className="card-content">
                    <div className="title_blog">
                      Why is the Tesla Cybertruck designed the way it is?
                    </div>
                    <p>An exploration into the truck's polarising design</p>
                  </div>
                </div>
              </Link>
              <Link
                className="item-action d-table"
                style={{ backgroundColor: "white" }}
              >
                <div className="card_blog">
                  <div className="card-header">
                    <img
                      src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg"
                      alt="rover"
                    />
                  </div>
                  <span className="tag-blog">Câu hỏi thường gặp</span>
                  <div className="card-content">
                    <div className="title_blog">
                      Why is the Tesla Cybertruck designed the way it is?
                    </div>
                    <p>An exploration into the truck's polarising design</p>
                  </div>
                </div>
              </Link>
              <Link
                className="item-action d-table"
                style={{ backgroundColor: "white" }}
              >
                <div className="card_blog">
                  <div className="card-header">
                    <img
                      src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg"
                      alt="rover"
                    />
                  </div>
                  <span className="tag-blog">Câu hỏi thường gặp</span>
                  <div className="card-content">
                    <div className="title_blog">
                      Why is the Tesla Cybertruck designed the way it is?
                    </div>
                    <p>An exploration into the truck's polarising design</p>
                  </div>
                </div>
              </Link>
              <Link
                className="item-action d-table"
                style={{ backgroundColor: "white" }}
              >
                <div className="card_blog">
                  <div className="card-header">
                    <img
                      src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg"
                      alt="rover"
                    />
                  </div>
                  <span className="tag-blog">Câu hỏi thường gặp</span>
                  <div className="card-content">
                    <div className="title_blog">
                      Why is the Tesla Cybertruck designed the way it is?
                    </div>
                    <p>An exploration into the truck's polarising design</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="homepage-notice py-4">
              <h2 className="text-center w-100 fw-bold text-capitalize mt-4 mb-4">
                THẬN TRỌNG VỚI HÀNG NỘI ĐỊA
              </h2>
              <div className="text-center mx-8">
                Để đảm bảo quyền lợi và lợi ích của quý khách hàng, Các sản phẩm
                này không được bảo hành chính hãng tại Việt Nam, có thể không sử
                dụng được đầy đủ chức năng trên ứng dụng và không được hỗ trợ kỹ
                thuật. Nếu gặp sự cố, quý khách sẽ phải tự tìm dịch vụ sửa chữa
                bên ngoài với chi phí cao. Ngoài ra, sản phẩm có thể bị khóa bất
                cứ lúc nào do không tương thích hoặc vi phạm quy định của nhà
                sản xuất, dẫn đến việc không thể sử dụng được robot.
              </div>
              <br />
              <div className="text-center text-underline">
                Xem hướng dẫn phân biệt hàng Quốc tế chính hãng và hàng Nội địa
              </div>
            </div>
          </div> */}
          <div className="py-4 policy_homepage">
            <h2 className="text-center w-100 fw-bold text-capitalize mt-5 mb-5">
              QUY ĐỊNH VÀ CHÍNH SÁCH
            </h2>
            <div className="text-center mx-8 ">
              Đối với Quý khách hàng, chúng tôi luôn mang đến những sản phẩm với
              chất lượng tốt nhất. Cùng với đó, sự quan tâm hàng đầu của chúng
              tôi là đặc biệt chú trọng đến dịch vụ chăm sóc, bảo hành trong
              suốt vòng đời sản phẩm.
            </div>

            <div>
              <button className="readmore_button mt-4">
                <a
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    outline: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  href="/chinh-sach-bao-hanh"
                >
                  XEM THÊM CHÍNH SÁCH
                </a>
              </button>
            </div>
          </div>
          <div className="py-4 homepage-notice mb-4">
            <h2 className="text-center w-100 fw-bold text-capitalize mt-5 mb-5">
              LIÊN HỆ BỘ PHẬN HỖ TRỢ
            </h2>
            <h4
              className="text-center mx-8 "
              style={{ textTransform: "upperCase" }}
            >
              Vui lòng liên hệ với chúng tôi nếu thông tin trên không thể <br />
              giải quyết được vấn đề của bạn
            </h4>
            <div>
              <button className="readmore_button mt-4">
                <input
                  type="tel"
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    outline: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  readOnly
                  value={"HOTLINE : 1900.633.870"}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBaoHanh;
