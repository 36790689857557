import { _LoginwithDaily } from "../../api/auth";
import {
  _getCategory,
  _getNewsEcovacsByCreateAt,
  _getNewsEcovacsById,
  _getListProductEcovacs,
  _getProductEcovacsId,
  _getCityEcovacs,
  _getListTTBaoHanh,
  _getCity,
  _getDistrict,
  _getWard,
  _getProductErp,
  _checkBaoHanh,
  _addBaoHanh,
  _traCuuBaoHanh,
  _danhSachSanPham,
  _danhSachPhuKien,
  _getThuonghieu,
  _getKichHoatBaoHanh,
  _getTenSanPham,
  _getProductName,
  _getTrungTamBaoHanh,
  _getMaDaiLyBan,
  _taoBaoHanhWeb,
  _uploadFile,
  _UpdateImageBaoHanh,
  _addFeedBack,
  _getDemDanhSachPhieuBaoHanh,
  _getDanhSachPhieuBaoHanh,
  _doimatkhau,
  _getYeuCauDetail,
} from "../../api/baoHanh";
import {
  GET_CATEGORY,
  GET_NEWS_ECOVACS_BY_CREATE_AT,
  GET_NEWS_ECOVACS_ID,
  GET_LIST_PRODUCT_ECOVACS,
  GET_PRODUCT_ECOVACS_ID,
  ADDRESS_NEWS_ECOVACS,
  GET_LIST_TT_BAOHANH,
  GET_CITY,
  GET_TENSANPHAM,
  GET_DISTRICT,
  GET_WARD,
  GET_PRODUCT_ERP,
  CHECK_BAO_HANH,
  ADD_BAO_HANH,
  TRA_CUU_BAO_HANH,
  DANH_SACH_SAN_PHAM_ECOVACS,
  DANH_SACH_PHU_KIEN_ECOVACS,
  GET_TRUNGTAMBAOHANH,
  GET_THUONGHIEU,
  GET_KICHHOATBAOHANH,
  GET_PRODUCTNAME,
  GET_MADAILYBAN,
  GET_TAOBAOHANHWEB,
  CAPNHAT_IMAGEBAOHANH,
  ADDFEEDBACK,
  GET_DanhSachPhieuBaoHanh,
  GET_DemDanhSachPhieuBaoHanh,
  GET_LOGIN,
  DOIMATKHAU,
  GET_YEUCAUDETAIL,
} from "../type";

export const getCategory = () => async (dispatch) => {
  try {
    _getCategory().then(function (res) {
      return dispatch({ type: GET_CATEGORY, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const AddFeedback = (body) => async (dispatch) => {
  try {
    _addFeedBack(body).then(function (res) {
      return dispatch({ type: ADDFEEDBACK, data: res });
    });
  } catch (error) {
    return error;
  }
};

export const getNewsEcovacsByCreateAt = () => async (dispatch) => {
  try {
    _getNewsEcovacsByCreateAt().then((res) => {
      return dispatch({ type: GET_NEWS_ECOVACS_BY_CREATE_AT, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const getThuongHieu = (id) => async (dispatch) => {
  try {
    _getThuonghieu(id).then(function (res) {
      return dispatch({ type: GET_THUONGHIEU, data: res });
    });
  } catch (error) {
    return error;
  }
};

export const getNewsEcovacsById = (id) => async (dispatch) => {
  try {
    _getNewsEcovacsById(id).then((res) => {
      return dispatch({ type: GET_NEWS_ECOVACS_ID, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getListProductEcovacs = () => async (dispatch) => {
  try {
    _getListProductEcovacs().then((res) => {
      return dispatch({ type: GET_LIST_PRODUCT_ECOVACS, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getProductEcovacsId = (id) => async (dispatch) => {
  try {
    _getProductEcovacsId(id).then((res) => {
      return dispatch({ type: GET_PRODUCT_ECOVACS_ID, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getCityEcovacs = () => async (dispatch) => {
  try {
    _getCityEcovacs().then((res) => {
      return dispatch({ type: ADDRESS_NEWS_ECOVACS, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getListTTBaoHanh = (idCity) => async (dispatch) => {
  try {
    _getListTTBaoHanh(idCity).then((res) => {
      return dispatch({ type: GET_LIST_TT_BAOHANH, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const getTenSanPham = (mahang) => async (dispatch) => {
  try {
    _getTenSanPham(mahang).then(function (res) {
      return dispatch({ type: GET_TENSANPHAM, data: res });
    });
  } catch (error) {
    return error;
  }
};

export const getCity = () => async (dispatch) => {
  try {
    _getCity().then((res) => {
      return dispatch({ type: GET_CITY, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const getProductName = (serial, manhomhang) => async (dispatch) => {
  try {
    _getProductName(serial, manhomhang).then(function (res) {
      return dispatch({ type: GET_PRODUCTNAME, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const getMaDaiLyBan = (id) => async (dispatch) => {
  try {
    _getMaDaiLyBan(id).then(function (res) {
      return dispatch({ type: GET_MADAILYBAN, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const getListTrungTamBaoHanh = () => async (dispatch) => {
  try {
    _getTrungTamBaoHanh().then(function (res) {
      return dispatch({ type: GET_TRUNGTAMBAOHANH, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const kichHoatBaoHanh = (body) => async (dispatch) => {
  try {
    _getKichHoatBaoHanh(body).then(function (res) {
      return dispatch({ type: GET_KICHHOATBAOHANH, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const UploadFile = (sochungtu, fileList) => async (dispatch) => {
  try {
    const response = await _uploadFile(sochungtu, fileList);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateImageBaoHanh = (sochungtu, filename) => async (dispatch) => {
  try {
    _UpdateImageBaoHanh(sochungtu, filename).then(function (res) {
      return dispatch({ type: CAPNHAT_IMAGEBAOHANH, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const TaoBaoHanhWeb = (body) => async (dispatch) => {
  try {
    return _taoBaoHanhWeb(body)
      .then((res) => {
        dispatch({ type: GET_TAOBAOHANHWEB, data: res });
        return res;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error;
  }
};

export const getDistrict = (id) => async (dispatch) => {
  try {
    _getDistrict(id).then((res) => {
      return dispatch({ type: GET_DISTRICT, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getWard = (districtId) => async (dispatch) => {
  try {
    _getWard(districtId).then((res) => {
      return dispatch({ type: GET_WARD, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const getProductErp = (params) => async (dispatch) => {
  try {
    _getProductErp(params).then((res) => {
      return dispatch({ type: GET_PRODUCT_ERP, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const checkBaoHanh = (params) => async (dispatch) => {
  try {
    _checkBaoHanh(params).then((res) => {
      return dispatch({ type: CHECK_BAO_HANH, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addBaoHanh = (body) => async (dispatch) => {
  try {
    _addBaoHanh(body).then((res) => {
      return dispatch({ type: ADD_BAO_HANH, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const traCuuBaoHanh = (params) => async (dispatch) => {
  try {
    _traCuuBaoHanh(params).then((res) => {
      return dispatch({ type: TRA_CUU_BAO_HANH, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const danhSachSanPham = (id) => async (dispatch) => {
  try {
    _danhSachSanPham(id).then((res) => {
      return dispatch({ type: DANH_SACH_SAN_PHAM_ECOVACS, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const danhSachPhuKien = (params) => async (dispatch) => {
  try {
    _danhSachPhuKien(params).then((res) => {
      return dispatch({ type: DANH_SACH_PHU_KIEN_ECOVACS, data: res });
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const login = (body) => async (dispatch) => {
  try {
    _LoginwithDaily(body).then(function (res) {
      return dispatch({ type: GET_LOGIN, data: res });
    });
  } catch (error) {
    return error;
  }
};
export const getDemDanhSachPhieuBaoHanh = (body) => async (dispatch) => {
  try {
    return _getDemDanhSachPhieuBaoHanh(body)
      .then((res) => {
        dispatch({ type: GET_DemDanhSachPhieuBaoHanh, data: res });
        return res;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error;
  }
};
export const getDanhSachPhieuBaoHanh = (body) => async (dispatch) => {
  try {
    return _getDanhSachPhieuBaoHanh(body)
      .then((res) => {
        dispatch({ type: GET_DanhSachPhieuBaoHanh, data: res });
        return res;
      })
      .catch((error) => {
        return error.message;
      });
  } catch (error) {
    return error;
  }
};
export const DoiMatKhau = (fileList) => async (dispatch) => {
  try {
    _doimatkhau(fileList).then(function (res) {
      return dispatch({ type: DOIMATKHAU, data: res });
    });
  } catch (error) {
    return error;
  }
};

export const getYeuCauDetail = (sochungtu) => async (dispatch) => {
  try {
    _getYeuCauDetail(sochungtu).then(function (res) {
      return dispatch({ type: GET_YEUCAUDETAIL, data: res });
    });
  } catch (error) {
    return error;
  }
};
