import React from "react"
import pdfChinhSach from '../../assets/pdf/chinh-sach-giga.pdf'
import { scroller, Link as LinkTo, Element } from 'react-scroll'

const linkPDF = 'https://gigadigital.vn/tin-tuc/khuyen-mai/sam-robot-hut-bui-ecovacs-nhan-ngay-bao-duong-xin#:~:text=H%E1%BB%93%20Ch%C3%AD%20Minh.-,C%E1%BB%A5%20th%E1%BB%83%2C%20g%C3%B3i%20d%E1%BB%8Bch%20v%E1%BB%A5%20v%E1%BB%87%20sinh%20Premium%20Care%20bao,th%E1%BA%BF%20mi%E1%BB%85n%20ph%C3%AD%20ch%E1%BB%95i%20ph%E1%BB%A5'

const HuongDan = () => {
  return (
    <div className="container chinh-sach">
      <div className="chinh-sach-bao-hanh-chung">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH BẢO HÀNH CHUNG</h5>
        <div className="content">
          <ul className="list-unstyled">
            <li className="mb-2">
              <p>Chúng tôi xin chân thành cảm ơn Quý khách hàng đã tin tưởng lựa chọn và mua sắm các sản phẩm tại GIGA.vn.</p>
            </li>
            <li className="mb-2">
              <p>GIGA.vn cam kết tất cả các sản phẩm bán ra tại hệ thống của chúng tôi đều là sản phẩm chính hãng, được bảo hành đúng theo quy định của Hãng sản xuất cũng như chính sách bảo hành riêng của chúng tôi.</p>
            </li>
            <li className="mb-2">
              <p>Dưới đây là chính sách bảo hành của GIGA.vn áp dụng chung cho tất cả các sản phẩm (<i>đối với các sản phẩm thương hiệu <strong>ECOVACS</strong>, chúng tôi có thêm một số lưu ý riêng so với Chính sách chung này, Quý khách vui lòng xem chi tiết <LinkTo className='text-primary text-decoration-underline cursor-pointer' to="section1" spy={true} smooth={true} duration={500}>tại đây</LinkTo> </i>). GIGA.vn luôn nỗ lực nhằm mang lại những trải nghiệm mua sắm hài lòng nhất đến Quý khách.</p>
            </li>
          </ul>
          <h6 className='mt-3 fw-bold'>1. NGUYÊN TẮC BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Chính sách bảo hành của GIGA.vn chỉ áp dụng cho các sản phẩm do GIGA.vn phân phối hoặc được ủy quyền tiếp nhận bảo hành từ hãng sản xuất.</p></li>
            <li className="mb-2"><p>- Chương trình bảo hành bắt đầu có hiệu lực từ thời điểm GIGA.vn xuất hóa đơn/Phiếu mua hàng/Phiếu xuất kho cho Quý khách. Thời hạn bảo hành được quy định trên từng đường link sản phẩm cụ thể được đăng tải trên website Giga.vn hoặc căn cứ thời hạn bảo hành trên chứng từ mua hàng <i>(với các sản phẩm được lựa chọn thời hạn bảo hành)</i>.</p></li>
            <li className="mb-2"><p>- Mỗi sản phẩm sẽ có chính sách, thời hạn bảo hành khác nhau tùy theo đặc thù của sản phẩm và quy định của Hãng sản xuất.</p> </li>
            <li className="mb-2"><p>- Để tìm hiểu thông tin chi tiết về chính sách bảo hành cho sản phẩm cụ thể, xin liên hệ bộ phận Tiếp nhận bảo hành của GIGA.vn theo hotline: 1900-633-870.</p></li>
            <li className="mb-2"><p>- Đối với các sản phẩm có sử dụng pin hoặc các phụ kiện điện (như điều khiển từ xa,...), thời hạn bảo hành của pin và các phụ kiện điện này sẽ được tính bằng nửa thời gian bảo hành của sản phẩm chính.</p></li>
            <li className="mb-2"><p>- Các linh kiện thay thế, sửa chữa hoặc sản phẩm đổi trả sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn</p></li>
          </ul>
          {/* <label className='d-block'>(Quý khách vui lòng đọc kĩ điều kiện bảo hành và đổi trả trong 30 ngày bên dưới)</label> */}
          <h6 className='mt-3 fw-bold'>2. ĐIỀU KIỆN BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Sản phẩm đủ điều kiện bảo hành theo công bố chính thức của hãng sản xuất. </p></li>
            <li className="mb-2"><p>- Sản phẩm còn vỏ sản phẩm cùng phụ kiện đi kèm (ví dụ: điều khiển, dây sạc, củ sạc, thẻ, chân đế, ốc vít, phụ kiện lắp đặt…)</p></li>
            <li className="mb-2"><p>- Số Seri, imei định danh sản phẩm phải rõ nét, trùng khớp với chứng từ mua bán hàng hóa.</p></li>
          </ul>
          <h6 className='mt-3 fw-bold'>3. CÁC TRƯỜNG HỢP KHÔNG ĐƯỢC BẢO HÀNH MIỄN PHÍ</h6>
          <i><strong>Những trường hợp dưới đây không nằm trong chính sách bảo hành miễn phí của Nhà sản xuất:</strong></i>
          <ul className="list-unstyled mt-3">
            <li className="mb-2">
              <p>- Sản phẩm có lỗi do người dùng lắp đặt, sử dụng, bảo quản sản phẩm không đúng hướng dẫn, khuyến cáo của Nhà sản xuất.</p>
            </li>
            <li className="mb-2">
              <p>- Người dùng can thiệp cải tạo hình thức, công năng máy, up ROM, chạy phần mềm không chính hãng, root máy, các hình thức can thiệp phần cứng và phần mềm khác...</p>
            </li>
            <li className="mb-2">
              <p>- Tài khoản và password của quý khách đăng nhập không hợp lệ (bao gồm cả trường hợp máy bật không lên).</p>
            </li>
            <li className="mb-2">
              <p>- Sản phẩm có dấu hiệu hư hỏng do bị cạy mở, mất, rách tem bảo hành, bị tác động của ngoại lực, bị động vật xâm nhập, sản phẩm bị tác động bởi hỏa hoạn, thiên tai, hóa chất, chất lỏng...</p>
            </li>
            <li className="mb-2">
              <p>- Lỗi do hao mòn tự nhiên.</p>
            </li>
            <li className="mb-2">
              <p>- Các vết trầy xước, cấn móp, tróc sơn không thuộc phạm vi bảo hành.</p>
            </li>

          </ul>
          <i><strong>Với các sản phẩm không thuộc phạm vi bảo hành miễn phí, GIGA.vn cung cấp dịch vụ chăm sóc, sửa chữa sản phẩm theo nhu cầu có tính phí (Quý khách tham khảo biểu phí dịch vụ <a href={pdfChinhSach} target="_blank" className='text-primary text-decoration-underline'>tại đây</a>).</strong></i>
          <h6 className='mt-3 fw-bold'>4. QUY TRÌNH BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- <strong>Thời gian thực hiện hiện việc bảo hành:</strong> thời gian tiêu chuẩn là 15 ngày làm việc tính từ ngày chúng tôi nhận được sản phẩm (không bao gồm ngày nghỉ, ngày lễ). <i>Thời gian bảo hành có thể sẽ được rút ngắn hoặc kéo dài phụ thuộc vào tình trạng sản phẩm và được chúng tôi thông báo cụ thể cho Quý khách.</i></p></li>
            <li className="mb-2"><p>- <strong>Địa điểm tiếp nhận sản phẩm cần bảo hành:</strong> Quý khách có thể trực tiếp mang sản phẩm tới tất cả các showroom của GIGA.vn trên toàn quốc, hoặc gửi chuyển phát nhanh tới trung tâm bảo hành gần nhất của chúng tôi theo hướng dẫn của nhân viên tư vấn (Hotline 1900-633-870). </p></li>
            <li className="mb-2"><p>- <strong>Phí vận chuyển sản phẩm bảo hành:</strong> Quý khách vui lòng thanh toán phí vận chuyển khi gửi hàng bảo hành, GIGA.vn sẽ thanh toán phí vận chuyển khi hoàn trả lại sản phẩm tới Quý khách. </p></li>
          </ul>
          <p><i>Lưu ý: Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành GIGA.vn không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.</i></p>
          <p className="my-3"><strong>- Quy trình thực hiện bảo hành sản phẩm:</strong></p>
          <div class="flowchart">
            <div class="item">
              <div class="content-detail">Khách hàng gửi thông tin yêu cầu bảo hành tới GIGA.VN</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA tiếp nhận sản phẩm gửi đến từ khách hàng</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA thực hiện đánh giá, đưa ra phương án sửa chữa bảo hành (Thông báo, xác nhận phí dịch vụ và sửa chữa nếu có từ khách hàng)</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">GIGA thông báo sản phẩm đã hoàn thành sửa chữa, xác nhận thông tin địa chỉ và thực hiện hoàn trả sản phẩm</div>
              <div class="arrow arrow-bar is-bottom"></div>
            </div>
            <div class="item">
              <div class="content-detail">Hoàn tất dịch vụ bảo hành khi khách hàng đã nhận lại sản phẩm.</div>
            </div>
          </div>
          {/* <h6 className='mt-3 fw-bold'> QÚY KHÁCH LƯU Ý!</h6>
          <ul>
            <li>
              Quý khách không nên sử dụng sạc không theo đúng quy chuẩn của nhà sản xuất (sẽ gây lỗi nguồn sản phẩm)
            </li>
            <li>
              Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành Giga Digital không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.
            </li>
            <li>Việc sửa chữa và linh kiện thay thế hoặc sản phẩm đổi sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn.
            </li>
          </ul> */}
        </div>
      </div>
      <div className="chinh-sach-bao-hanh-giga mt-5">
      <Element name="section1" classID="section1">
        <h5 className="fw-bold text-center text-primary">CHÍNH SÁCH BẢO HÀNH CỦA GIGA.VN ĐỐI VỚI SẢN PHẨM ECOVACS</h5>
      </Element>
        <div className="content">
          <p className="mt-3"><i>Chính sách bảo hành này được áp dụng riêng cho các sản phẩm mang thương hiệu ECOVACS. Các nội dung không được sửa đổi, bổ sung hay quy định chi tiết bởi chính sách riêng này sẽ tuân thủ theo quy định bảo hành chung của GIGA.vn</i></p>
          <h6 className='mt-3 fw-bold'>1. SẢN PHẨM ĐƯỢC HƯỞNG CHÍNH SÁCH BẢO HÀNH MIỄN PHÍ</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Các sản phẩm mang nhãn hiệu Ecovacs do GIGA.vn cung cấp.</p></li>
            <li className="mb-2"><p>- Bảo hành do lỗi vật liệu và gia công trên sản phẩm trong điều kiện sử dụng bình thường. </p></li>
            <li className="mb-2"><p>- Chỉ bảo hành cho sản phẩm chính.</p> </li>
            <li className="mb-2"><p>- Chỉ bảo hành khi sản phẩm còn tem serial.</p></li>
          </ul>

          <h6 className='mt-3 fw-bold'>2. CÁC TRƯỜNG HỢP KHÔNG ÁP DỤNG CHÍNH SÁCH BẢO HÀNH MIỄN PHÍ</h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- KHÔNG áp dụng bảo hành đối với cảm ứng và màn hình của máy.</p></li>
            <li className="mb-2"><p>- KHÔNG bảo hành các thành phần dễ ăn mòn như: Pin điều khiển từ xa, giẻ lau nhà, cây lau nhà, bộ lọc, chổi chính, chổi phụ, dung dịch làm sạch, cốc và chổi cao su, bánh xe…</p></li>
            <li className="mb-2"><p>- KHÔNG áp dụng cho phụ kiện đi kèm sản phẩm chính.</p> </li>
          </ul>
          <h6 className='mt-3 fw-bold'>3. CAM KẾT BẢO HÀNH</h6>
          <ul className="list-unstyled">
            <li className="mb-2">
              <p>- GIGA.vn cam kết:</p>
              <ul style={{ listStyleType: 'none' }} className="mt-2">
                <li className="mb-2">
                  <p>+ Hoàn thành quy trình bảo hành trong vòng tối đa 15 ngày (tính từ ngày nhận máy ở trạng thái lỗi đến ngày tiến hành xong việc bảo hành).</p>
                </li>
                <li className="mb-2">
                  <p>+ Sản phẩm không phải bảo hành lại trong vòng 30 ngày kể từ ngày khách hàng nhận được sản phẩm đã hoàn thành quy trình bảo hành.</p>
                </li>
              </ul>
            </li>
            <li className="mb-2"><p>- Nếu GIGA.vn vi phạm cam kết nêu trên, chúng tôi sẽ gửi tặng Quý khách gói dịch vụ Premium Care miễn phí như sau:</p></li>

          </ul>
          <table className="border-1 my-3">
            <thead>
              <tr>
                <th className="text-center text-primary">Sản phẩm có giá dưới 10 triệu đồng.</th>
                <th className="text-center text-primary">Sản phẩm có giá trên 10 triệu đồng.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">Gói Premium Care thời hạn sử dụng trong 1 tháng.</td>
                <td className="text-center">Gói Premium Care thời hạn sử dụng trong 2 tháng.</td>
              </tr>

            </tbody>
          </table>
          <p>Chi tiết về gói Premium Care Quý khách vui lòng xem <a href={linkPDF} target="_blank" className='text-primary text-decoration-underline'>tại đây</a></p>
          <h6 className='mt-3 fw-bold'>4. KHUYẾN CÁO </h6>
          <ul className="list-unstyled">
            <li className="mb-2"><p>- Quý khách không nên sử dụng sạc không theo đúng quy chuẩn của nhà sản xuất (sẽ gây lỗi nguồn sản phẩm), không sử dụng các loại nước lau sàn/tinh dầu không phải do Ecovacs sản xuất (tránh việc chất lỏng không tương thích làm giảm tuổi thọ hoặc làm hỏng robot).</p></li>
            <li className="mb-2"><p>- Quý khách vui lòng tự sao lưu tất cả các dữ liệu cá nhân trước khi mang sản phẩm tới trung tâm bảo hành, trong quá trình bảo hành GIGA.vn không chịu trách nhiệm về mọi mất mát dữ liệu cá nhân và các ứng dụng cài đặt thêm được lưu trữ trong máy.</p></li>
            <li className="mb-2"><p>- Các linh kiện thay thế, sửa chữa hoặc sản phẩm đổi trả sẽ được bảo hành 3 tháng tính từ ngày khách hàng nhận lại sản phẩm, hoặc theo thời hạn bảo hành còn lại của sản phẩm, tùy theo thời hạn nào dài hơn.</p> </li>
          </ul>

        </div>
      </div>
    
    </div>
  )
}

export default HuongDan