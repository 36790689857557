
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { getNewsEcovacsById, getProductEcovacsId } from "../../redux/category/action"
import ReactHtmlParser from 'react-html-parser'
import moment from "moment"
import { checkImageNews } from "../../utilities/checkImage"

const ChiTietTinTuc = () => {

  const dispatch = useDispatch()

  const { id } = useParams()

  const newEcovasId = useSelector(store => store.categories.newEcovasId)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getNewsEcovacsById(id))
  }, [id])

  return (
    <div className="container">
      <div className="detail-news">
        <div className="content-detail mt-4">
          <h2 className="fw-500">{newEcovasId?.title}</h2>
          <p className="text-gray mt-3">{moment(newEcovasId?.createdAt).format('DD MMMM, YYYY')}</p>
          <p className="image mt-2">
            <img src={checkImageNews(newEcovasId?.avatar)} className='img-fluid' />
          </p>
          <div className="detail-new mt-3">
            {
              ReactHtmlParser(newEcovasId?.post)
            }
          </div>
        </div>

      </div>
    </div>

  )
}

export default ChiTietTinTuc