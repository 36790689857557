import React from 'react'

const Footer = () => {
  
  return (
    <div className='footer'>
      <div className='container mt-3'>
        <div className='row'>
            <div className='col-md-12 text-center'>
              <h6><b>ECOVACS ROBOTICS</b></h6>
              <p className='mb-2'><b>VPGD: </b><span> Số 55 Thái Hà - Đống Đa - Hà Nội </span></p>

              <p className='mb-2'><b>Email: </b><span><a href="mailto:support@ecovacsrobotics.vn"></a>support@ecovacsrobotics.vn</span></p>
              <p className='mb-2'><b>SĐT: </b><span><a href='tel:+0787070825'></a> 1900.633.870 </span></p>
              {/* <p className='mb-2'><b>Liên hệ đối tác và đại lý: </b><span> 0966.933.737 </span></p>
              <p className='mb-2'><b>Hotline bán lẻ và bảo hành: </b><span> 0966.061.170 </span></p>
              <p><b>Email: </b><span> info@gigadigital.vn </span></p> */}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Footer