import React, {Fragment } from "react"
import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"
import { withRouter } from "react-router-dom"

const Home = ({ children }) => {

  return (
          <div className="hoplongtech">
            <Header/>
              {children}
              <Footer />
          </div>

  )
}

export default withRouter(Home)