import { SET_CAPTCHA, SET_LOADING } from "../type";

const initialState = {
  isloading: false,
  isCaptcha: false,
};
const loading = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isloading: action.data,
      };
    case SET_CAPTCHA:
      return {
        ...state,
        isCaptcha: action.data,
      };
    default:
      return state;
  }
};

export default loading;
