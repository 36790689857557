export const GET_CATEGORY = "GET_CATEGORY";
export const GET_NEWS_ECOVACS_BY_CREATE_AT = "GET_NEWS_ECOVACS_BY_CREATE_AT";
export const GET_NEWS_ECOVACS_ID = "GET_NEWS_ECOVACS_ID";
export const GET_LIST_PRODUCT_ECOVACS = "GET_LIST_PRODUCT_ECOVACS";
export const GET_PRODUCT_ECOVACS_ID = "GET_PRODUCT_ECOVACS_ID";
export const ADDRESS_NEWS_ECOVACS = "ADDRESS_NEWS_ECOVACS";
export const GET_LIST_TT_BAOHANH = "GET_LIST_TT_BAOHANH";

export const GET_CITY = "GET_CITY";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_WARD = "GET_WARD";

export const GET_PRODUCT_ERP = "GET_PRODUCT_ERP";
export const CHECK_BAO_HANH = "CHECK_BAO_HANH";
export const ADD_BAO_HANH = "ADD_BAO_HANH";
export const TRA_CUU_BAO_HANH = "TRA_CUU_BAO_HANH";

export const DANH_SACH_SAN_PHAM_ECOVACS = "DANH_SACH_SAN_PHAM_ECOVACS";
export const DANH_SACH_PHU_KIEN_ECOVACS = "DANH_SACH_PHU_KIEN_ECOVACS";

export const GET_THUONGHIEU = "GET_THUONGHIEU";
export const GET_KICHHOATBAOHANH = "GET_KICHHOATBAOHANH";

export const GET_PRODUCTNAME = "GET_PRODUCTNAME";

export const GET_MADAILYBAN = "GET_MADAILYBAN";
export const GET_TRUNGTAMBAOHANH = "GET_TRUNGTAMBAOHANH";
export const GET_TENSANPHAM = "GET_TENSANPHAM";
export const GET_TAOBAOHANHWEB = "GET_TAOBAOHANHWEB";
export const CAPNHAT_IMAGEBAOHANH = "CAPNHAT_IMAGEBAOHANH";

export const SET_LOADING = "SET_LOADING";
export const SET_CAPTCHA = "SET_CAPTCHA";

export const GET_DemDanhSachPhieuBaoHanh = "GET_DemDanhSachPhieuBaoHanh";
export const GET_DanhSachPhieuBaoHanh = "GET_DanhSachPhieuBaoHanh";
export const GET_YEUCAUDETAIL = "GET_YEUCAUDETAIL";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const DOIMATKHAU = "DOIMATKHAU";
export const ADDFEEDBACK = "ADDFEEDBACK";
export const GET_LOGIN = "GET_LOGIN";
