import React, { Fragment, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import IconGiga from '../../assets/image/logo.jpg'
import logoEcovacsNew from '../../assets/image/logo-ecovacs-new.png'

const useViewPort = () => {

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
  }, [])
  return { width }
}

const logoEcovacs = 'https://x2omni.giga.vn/static/media/ecovacs-logo-vector.3fafede15d4e80548fea8478ecb01fe3.svg'

const Header = () => {
  
  const location = useLocation()

  const [click, setClick] = useState(false)
  const [activeLink, setActiveLink] = useState('')

  const viewPort = useViewPort();
  const isDesktop = viewPort.width >= 768

  useEffect(() => {
    setActiveLink(location.pathname) // Cập nhật activeLink với đường dẫn hiện tại
  }, [location])

  const handleBlur = () => {
    setClick(false)
  }

  return (
    <Fragment>
      <div className='p-0' id='top'>
        <Container fluid={true} className="search-page container">
          <div className='position-relative main-header d-flex align-items-center'>
            <div className="navbar-header d-flex align-items-center navbar-expand-md me-5">
              <Link to={'/'} className="text-gray fw-bold logo-desktop" >
                <img src={logoEcovacsNew} className='position-relative' style={{ width: 200, height: 60, objectFit: 'contain' }} alt='logo' />
              </Link>
              <Link to={'/'} className="text-gray fw-bold logo-mobile" >
                <img src={IconGiga} style={{ width: 60 }} />
              </Link>
              <button className="navbar-toggler" onClick={() => setClick(!click)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                <i className="fas fa-bars text-gray fw-bold"></i>
              </button>
            </div>
            {isDesktop && (
              <div className="list-navbar-items align-items-center  navbar-collapse" id="navbarSupportedContent">
                <ul className='list-unstyled d-flex align-items-center mb-0 ' >
                  <li className='me-4'> <Link to={'/'}  className={`text-gray fw-bold ${activeLink === '/' ? 'active' : ''}`}>Trang chủ</Link></li>
                  <li className='me-4'> <Link to={'/kich-hoat-bao-hanh'}  className={`text-gray fw-bold ${activeLink === '/kich-hoat-bao-hanh' ? 'active' : ''}`}>Kích hoạt</Link></li>
                  <li className='me-4'> <Link to={'/tra-cuu-bao-hanh'} className={`text-gray fw-bold ${activeLink === '/tra-cuu-bao-hanh' ? 'active' : ''}`}>Tra cứu thời hạn</Link></li>
                  <li className='me-4'> <Link to={'/gui-yeu-cau'} className={`text-gray fw-bold ${activeLink === '/gui-yeu-cau' ? 'active' : ''}`}>Tạo phiếu bảo hành</Link></li>
                  {/* <li className='me-4'> <Link to={'/tram-bao-hanh'}  className={`text-gray fw-bold ${activeLink === '/tram-bao-hanh' ? 'active' : ''}`}>Liên hệ</Link></li> */}
                  <li className='me-4'> <Link to={'/chinh-sach-bao-hanh'}  className={`text-gray fw-bold ${activeLink === '/chinh-sach-bao-hanh' ? 'active' : ''}`}>Chính sách bảo hành</Link></li>
                  <li className='me-4'> <Link to={'/product-selector'}  className={`text-gray fw-bold ${activeLink === '/product-selector' ? 'active' : ''}`}>Phụ kiện Ecovacs</Link></li>
                </ul>
              </div>
            )}
            {
              click && (
                <div className="list-navbar-items align-items-center justify-content-between w-100  navbar-collapse 111" id="navbarSupportedContent">
                  <ul className='list-unstyled d-flex align-items-center mb-0 justify-content-center' >
                    <li className='p-2'> <Link to={'/'} className='text-gray fw-bold'>Trang chủ</Link></li>
                    <li className='p-2'> <Link to={'/kich-hoat-bao-hanh'} className='text-gray fw-bold'>Kích hoạt</Link></li>
                    <li className='p-2'> <Link to={'/tra-cuu-bao-hanh'} className='text-gray fw-bold'>Tra cứu thời hạn</Link></li>
                    <li className='p-2'> <Link to={'/gui-yeu-cau'} className='text-gray fw-bold'>Tạo phiếu bảo hành</Link></li>
                    {/* <li className='p-2'> <Link to={'/tram-bao-hanh'} className='text-gray fw-bold'>Liên hệ</Link></li> */}
                    <li className='p-2'> <Link to={'/chinh-sach-bao-hanh'} className='text-gray fw-bold'>Chính sách bảo hành</Link></li>
                    <li className='p-2'> <Link to={'/product-selector'} className='text-gray fw-bold'>Product Selector</Link></li>
                  </ul>
                </div>
              )
            }
            <ul className='list-unstyled list-data d-flex align-items-center mb-0'>
              <li className='me-4'>  <a target={'_blank'} href='https://www.facebook.com/ecovacspro' className='text-primary'><i className='fa fa-facebook text-gray fw-bold'></i></a></li>
              <li className=''>  <a className='text-primary' href='mailto:support@ecovacsrobotics.vn'>
                <i className='fa fa-envelope-o text-gray fw-bold'></i></a></li>
            </ul>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}

export default Header