import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { _getBaoHanh } from "../../api/baoHanh";
import moment from "moment";

import image from "../../assets/image/L12.jpg";
import CH1918_White from "../../assets/image/sanPham/CH1918_(White).png";
import D_CC03_2115 from "../../assets/image/sanPham/D-CC03-2115.png";
import D_CC3H from "../../assets/image/sanPham/D-CC3H.png";
import D_CC3I from "../../assets/image/sanPham/D-CC3I.png";
import D_DB03_2014 from "../../assets/image/sanPham/D-DB03-2014.png";
import D_DZ03_0005 from "../../assets/image/sanPham/D-DZ03-0005.png";
import D_DZ03_0006 from "../../assets/image/sanPham/D-DZ03-0006.png";
import D_DZ03_2050 from "../../assets/image/sanPham/D-DZ03-2050.png";
import D_KT01_0017 from "../../assets/image/sanPham/D-KT01-0017.png";
import DX5G_KTA from "../../assets/image/sanPham/DX5G-KTA.png";
import N8_PRO from "../../assets/image/sanPham/n8_pro.png";
import OZMO950 from "../../assets/image/sanPham/ozmo950.png";
import T9 from "../../assets/image/sanPham/t9.png";
import T10 from "../../assets/image/sanPham/t10.png";
import U2 from "../../assets/image/sanPham/u2_pro.png";
import X1_OMNI from "../../assets/image/sanPham/x1_omni.png";
import { toast } from "react-toastify";

const TraCuu = () => {
  const [serial, setSerial] = useState("");
  const [ttBaoHanh, setTtBaoHanh] = useState(null);

  const onClick = () => {
    setTtBaoHanh(null);
    const body = {
      serial: serial,
    };
    if (serial != "") {
      _getBaoHanh(body)
        .then((res) => {
          if (!res) {
            toast.info(
              "Serial không tồn tại trong hệ thống! Vui lòng kiểm tra lại"
            );
          } else {
            setTtBaoHanh(res);
          }
        })
        .catch((errors) => {
          setTtBaoHanh(false);
        });
    } else {
      toast.info("Bạn cần điền số serial trước");
    }
  };

  return (
    <div className="container tra-cuu">
      <div className="content align-middle">
        <div className="title-content text-center mb-3">
          <h3 className="text-uppercase fw-bold">Tra cứu thời hạn bảo hành</h3>
          <h6 className="fw-bold">
            Dịch vụ bảo hành, sửa chữa, chăm sóc tư vấn sản phẩm
          </h6>
        </div>
        <h6 className="text-center">
          Vui lòng điền dãy số Series của thiết bị cần bảo hành vào trường nhập
          dưới đây
        </h6>
        <div className="mb-4 mt-2 d-flex justify-content-center">
          <input
            className="form-control w-50 rounded-0"
            placeholder="Nhập mã số đề nghị bảo hành"
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
          />
          <button className="giga-kich-hoat-btn p-2" onClick={onClick}>
            Tra cứu
          </button>
        </div>
        <div className="row ">
          <div className="col-md-12">
            {ttBaoHanh == false && (
              <div>
                <h6 className="text-center">
                  Chưa có thông tin kích hoạt bảo hành cho sản phẩm này
                </h6>
              </div>
            )}
            {ttBaoHanh && (
              <div>
                <ScrollAnimation animateIn="fadeInDown" animateOut="fadeInDown">
                  <h6 className="d-block text-center mt-3">
                    Sản phẩm chính hãng 100% được phân phối bởi Hoplongtech là
                    đại lý ủy quyền chính thức của Ecovacs tại Việt Nam
                  </h6>
                </ScrollAnimation>
                <ItemBaoHanh ttBaoHanh={ttBaoHanh} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraCuu;

const ItemBaoHanh = (props) => {
  const { ttBaoHanh } = props;

  if (ttBaoHanh) {
    if (ttBaoHanh.MA_CHUAN.indexOf("OZMO950") != -1) {
      ttBaoHanh.HINH_ANH = OZMO950;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("N8 Pro") != -1) {
      ttBaoHanh.HINH_ANH = N8_PRO;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("T9") != -1) {
      ttBaoHanh.HINH_ANH = T9;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-CC3H") != -1) {
      ttBaoHanh.HINH_ANH = D_CC3H;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-CC3I") != -1) {
      ttBaoHanh.HINH_ANH = D_CC3I;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-KT01-0017") != -1) {
      ttBaoHanh.HINH_ANH = D_KT01_0017;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("DX5G-KTA") != -1) {
      ttBaoHanh.HINH_ANH = DX5G_KTA;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-DB03-2014") != -1) {
      ttBaoHanh.HINH_ANH = D_DB03_2014;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-DZ03-2050") != -1) {
      ttBaoHanh.HINH_ANH = D_DZ03_2050;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-DZ03-0005") != -1) {
      ttBaoHanh.HINH_ANH = D_DZ03_0005;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-DZ03-0006") != -1) {
      ttBaoHanh.HINH_ANH = D_DZ03_0006;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("D-CC03-2115") != -1) {
      ttBaoHanh.HINH_ANH = D_CC03_2115;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("X1 OMNI") != -1) {
      ttBaoHanh.HINH_ANH = X1_OMNI;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("CH1918 (White)") != -1) {
      ttBaoHanh.HINH_ANH = CH1918_White;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("T10") != -1) {
      ttBaoHanh.HINH_ANH = T10;
    }
    if (ttBaoHanh.MA_CHUAN.indexOf("U2 Pro") != -1) {
      ttBaoHanh.HINH_ANH = U2;
    }
  }

  return (
    <div className="col-md-12 border-bottom content-detail">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10 mt-4 mb-3">
          <div className="row">
            <div className="col-md-7 ">
              <div className="p-3">
                <div className="row">
                  <label className="col-md-4 text-end col-form-label middle fw-bold">
                    Tên sản phẩm:
                  </label>
                  <h6 className="col-md-8">
                    <label className="col-form-label fw-bold text-primary">
                      {ttBaoHanh.TEN_HANG}
                    </label>
                  </h6>
                </div>

                <div className="row">
                  <label className="col-md-4 text-end col-form-label middle fw-bold">
                    Số Serial Number:
                  </label>
                  <h6 className="col-md-8">
                    <label className="col-form-label fw-bold text-primary">
                      {ttBaoHanh.SERIAL}
                    </label>
                  </h6>
                </div>

                <div className="row">
                  <label className="col-md-4 text-end col-form-label middle fw-bold">
                    Ngày kích hoạt:
                  </label>
                  <h6 className="col-md-8">
                    <label className="col-form-label fw-bold text-primary">
                      {moment(ttBaoHanh.NGAY_KICH_HOAT_BH).format(
                        "DD/MM/YYYY "
                      )}
                    </label>
                  </h6>
                </div>

                <div className="row">
                  <label className="col-md-4 text-end col-form-label middle fw-bold">
                    Thời gian bảo hành:
                  </label>
                  <h6 className="col-md-8">
                    <label className="col-form-label fw-bold text-primary">
                      {ttBaoHanh.SO_THANG_BAO_HANH} tháng
                    </label>
                  </h6>
                </div>
                <div className="row">
                  <label className="col-md-4 text-end col-form-label middle fw-bold">
                    Ngày hết hạn bảo hành:
                  </label>
                  <h6 className="col-md-8">
                    <label className="col-form-label fw-bold text-primary">
                      {moment(ttBaoHanh.HAN_BAO_HANH).format("DD/MM/YYYY ")}
                    </label>
                  </h6>
                </div>
              </div>
            </div>

            {/* <div className='col-md-12 hidden-md'>
              <div className='p-3'>
                <div className='row'>
                  <label className='col-md-4 text-end col-form-label middle fw-bold'>Sản phẩm:</label>
                  <label className='col-md-8 col-form-label fw-bold text-primary'>{ttBaoHanh.TEN_HANG}</label>
                </div>

                <div className='row'>
                  <label className='col-md-4 text-end col-form-label middle fw-bold'>Mã Series:</label>
                  <label className='col-md-8 col-form-label fw-bold text-primary'>{ttBaoHanh.SERIAL}</label>
                </div>

                <div className='row'>
                  <label className='col-md-4 text-end col-form-label middle fw-bold'>Ngày kích hoạt:</label>
                  <label className='col-md-8 col-form-label fw-bold text-primary'>{moment(ttBaoHanh.NGAY_KICH_HOAT_BH).format('DD/MM/YYYY ')}</label>
                </div>

                <div className='row'>
                  <label className='col-md-4 text-end col-form-label middle fw-bold'>Thời gian bảo hành:</label>
                  <label className='col-md-8 col-form-label fw-bold text-primary'>{ttBaoHanh.SO_THANG_BAO_HANH} tháng</label>
                </div>
                
                <div className='row'>
                  <label className='col-md-4 text-end col-form-label middle fw-bold'>Ngày hết hạn bảo hành:</label>
                  <label className='col-md-8 col-form-label fw-bold text-primary'>{moment(ttBaoHanh.HAN_BAO_HANH).format('DD/MM/YYYY ')}</label>
                </div>

              </div>
            </div> */}
            <div className="col-md-5 hidden-xs">
              <div className="row">
                <div className="col-md-12 col-form-label d-flex align-items-center justify-content-center ">
                  <img
                    className="img-product w-100"
                    src={image}
                    style={{ height: 300 }}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
};
