import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import imageBanner from "../../assets/image/L12.jpg";
import imageBanner1 from "../../assets/image/L13.jpg";

import {
  _guiYeuCauBaoHanh,
  _checkBaoHanh,
  _addBaoHanh,
} from "../../api/baoHanh";
import {
  getCity,
  getDistrict,
  getWard,
  getProductErp,
  getProductName,
  getListTrungTamBaoHanh,
  TaoBaoHanhWeb,
  getTenSanPham,
  getYeuCauDetail,
} from "../../redux/category/action";
import { Table, DatePicker, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { _kichHoatBaoHanh, _getBaoHanh } from "../../api/baoHanh";
import { ceil, findIndex, result } from "lodash";
import pdfFile from "../../assets/pdf/chinh-sach-dich-vu-giga.pdf";
import moment from "moment";
import { compareDatewithNow, formatDate } from "../../helper/convertDate";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";

const YeuCauDetail = ({ sochungtu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, watch, setValue } = useForm({
    shouldUseNativeValidation: true,
  });

  const [checktensanpham, setCheckTenSanPham] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const yeucaudetail = useSelector((store) => store.categories.getYeuCauDetail);

  const [currentTab, setCurrentTab] = useState(2);

  const { id } = useParams();
  const listCity = useSelector((store) => store.categories.listCity);
  const listDistrict = useSelector((store) => store.categories.listDistrict);

  const listWard = useSelector((store) => store.categories.listWard);
  const listTTBH = useSelector((store) => store.categories.getTrungTamBaoHanh);

  const [city, setCity] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [district, setDistrict] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [ward, setWard] = useState(null);
  const [wardId, setWardId] = useState(null);
  const [zoomImage, setZoomImage] = useState("");
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  useEffect(() => {
    if (sochungtu != null) {
      dispatch(getYeuCauDetail(sochungtu));
    }
    if (id != null) {
      dispatch(getYeuCauDetail(id));
    }
  }, [sochungtu]);

  const showModalImage = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getCity());
    dispatch(getListTrungTamBaoHanh());
  }, []);

  useEffect(() => {
    if (districtId != null) {
      dispatch(getWard(districtId));
    }
  }, [districtId]);

  useEffect(() => {
    findIndex(listDistrict, (item) => {
      if (item.DistrictName == yeucaudetail?.TINH_THANH_PHO) {
        setDistrictId(item.DistrictID);
      }
    });
  }, [district]);

  useEffect(() => {
    findIndex(listWard, (item) => {
      if (item.WardName == ward) {
        setWardId(item.DistrictID);
      }
    });
  }, [ward]);

  const onSubmitForm2 = async (data) => {
    setCurrentTab(3);
  };
  const onSubmitForm3 = async (data) => {
    setCurrentTab(4);
  };
  const onSubmitForm4 = async (data) => {
    history.push(`/tra-cuu-tinh-trang-bao-hanh`);
  };

  const handleImageClick = (fileName) => {
    setZoomImage(fileName);
    setIsLightboxVisible(true);
  };

  return (
    <>
      <div className="chinh-sach">
        <div className="chinh-sach-bao-hanh-chung">
          {/* ------ */}
          {/* Tab 2 */}
          <form onSubmit={handleSubmit(onSubmitForm2)}>
            {currentTab == "2" && (
              <div className="Tab 2">
                <h5 className="fw-bold text-center title_important ">
                  TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE
                </h5>
                <div className="content row progress_step">
                  <div className="col-3" id="comment_bubblefirst">
                    Thông tin liên hệ
                  </div>
                  <div id="comment_bubble" className="col-3 disable">
                    Thông tin sản phẩm
                  </div>
                  <div id="comment_bubble" className="col-3 disable">
                    Thông tin vận chuyển
                  </div>
                </div>
                <br />
                <div className="content row ">
                  <div className="col-sm-9">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">
                          1. THÔNG TIN LIÊN HỆ
                        </p>
                      </li>
                      <li className="mb-2">
                        <p>
                          Bạn là người sử dụng sản phẩm hay đơn vị bán lẻ?
                          <span className="require">*</span>
                        </p>
                      </li>
                    </ul>

                    <div class="mb-4 flex items-center">
                      <input
                        {...register("ROLE_USER")}
                        id="IS_NGUOI_DUNG"
                        type="radio"
                        disabled
                        value="Tôi là người sử dụng sản phẩm"
                        checked={
                          yeucaudetail?.ROLE_USER ===
                          "Tôi là người sử dụng sản phẩm"
                        }
                        class="bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label
                        for="IS_NGUOI_DUNG"
                        class="text-gray-900 dark:text-gray-300 ms-2 text-sm font-medium"
                      >
                        Tôi là người sử dụng sản phẩm
                      </label>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-12 responsive_input"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="col-md-3 responsive_checkbox"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            {...register("ROLE_USER")}
                            id="other"
                            disabled
                            type="radio"
                            checked={
                              yeucaudetail?.ROLE_USER === "Tôi là đơn vị bán lẻ"
                            }
                            value="Tôi là đơn vị bán lẻ"
                            class=" h-4 w-4"
                          />
                          <label for="other" class=" ms-2 text-sm font-medium">
                            Tôi là đơn vị bán lẻ:{" "}
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="text"
                            disabled
                            {...register("MA_KHACH_HANG_DAI_LY")}
                            value={yeucaudetail?.MA_KHACH_HANG_DAI_LY}
                            placeholder="Vui lòng nhập KHGD"
                            className="input_yeucau"
                          />
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row">
                      <div
                        className="col-md-12 my-3 responsive_input"
                        style={{ display: "flex" }}
                      >
                        <div className="col-md-3 responsive_checkbox">
                          <label for="other" class=" ms-2 text-sm font-medium">
                            Họ và tên của bạn:<span className="require">*</span>
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="text"
                            disabled
                            value={yeucaudetail?.HO_VA_TEN}
                            {...register("HO_VA_TEN")}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-12 my-3 responsive_input"
                        style={{ display: "flex" }}
                      >
                        <div className="col-md-3 responsive_checkbox">
                          <label for="other" class=" ms-2 text-sm font-medium">
                            Số điện thoại liên hệ:
                            <span className="require">*</span>
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="number"
                            disabled
                            value={yeucaudetail?.SO_DIEN_THOAI}
                            {...register("SO_DIEN_THOAI")}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-12 my-3 responsive_input"
                        style={{ display: "flex" }}
                      >
                        <div className="col-md-3 responsive_checkbox">
                          <label for="other" class=" ms-2 text-sm font-medium">
                            Email của bạn:
                          </label>
                        </div>
                        <div className="col-md-9 responsive_checkbox">
                          <input
                            type="email"
                            disabled
                            value={yeucaudetail?.EMAIL}
                            {...register("EMAIL")}
                            placeholder="Vui lòng nhập câu trả lời"
                            className="input_yeucau"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 prestep_button">
                      <button
                        className=" mb-3 rounded border bg-transparent px-4 py-2  font-semibold rounded-md  shadow-lg "
                        style={{ marginRight: "5px" }}
                        onClick={() => setCurrentTab(1)}
                      >
                        Quay lại
                      </button>
                      <button
                        type="submit"
                        className=" mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold shadow-lg hover:border-transparent"
                      >
                        Tiếp
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img
                      alt="Hình ảnh"
                      style={{ height: "20rem" }}
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src={imageBanner1}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
          {/* ------ */}

          {/* Tab 3 */}
          <form onSubmit={handleSubmit(onSubmitForm3)}>
            {currentTab == "3" && (
              <div className="Tab 3">
                <h5 className="fw-bold text-center title_important ">
                  TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE
                </h5>
                <div className="content row progress_step">
                  <div className="col-3" id="comment_bubblefirst">
                    Thông tin liên hệ
                  </div>
                  <div id="comment_bubble" className="col-3">
                    Thông tin sản phẩm
                  </div>
                  <div id="comment_bubble" className="col-3 disable">
                    Thông tin vận chuyển
                  </div>
                </div>
                <div className="content row ">
                  <div className="col-sm-10">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">
                          2. THÔNG TIN SẢN PHẨM
                        </p>
                      </li>
                    </ul>
                    <div className="col-md-12 responsive_input">
                      <div className="title_yeucau col-md-12 ">
                        Sản phẩm của bạn thuộc thương hiệu nào?
                        <span className="require">*</span>
                      </div>

                      <div className="my-2 col-md-12">
                        <form className="mx-auto max-w-sm">
                          <select
                            {...register("THUONG_HIEU")}
                            id="countries"
                            style={{ width: "100%" }}
                            value={yeucaudetail?.THUONG_HIEU}
                            className=" w-full border p-2 "
                          >
                            <option value=""></option>
                            <option value="Ecovacs">Ecovacs</option>
                            <option value="Tineco">Tineco</option>
                            <option value="Kingsmith">Kingsmith</option>
                            <option value="New Widetech">New Widetech</option>
                            <option value="Mibro">Mibro</option>
                            <option value="Imilab">Imilab</option>
                            <option value="Xiaomi">Xiaomi</option>
                            <option value="Cuckoo">Cuckoo</option>
                            <option value="Thương hiệu khác">
                              Thương hiệu khác
                            </option>
                          </select>
                        </form>
                      </div>
                    </div>
                    <br />
                    {yeucaudetail?.THUONG_HIEU ===
                    "" ? null : yeucaudetail?.THUONG_HIEU ===
                      "Thương hiệu khác" ? (
                      <>
                        {yeucaudetail.ROLE_USER ===
                          "Tôi là người sử dụng sản phẩm" && (
                          <>
                            <div className="col-md-12 form-inline title_yeucau">
                              Bạn mua sản phẩm này theo kênh thương mại nào?{" "}
                              <span className="require">*</span>{" "}
                            </div>{" "}
                            <br />
                            <div
                              className="col-md-12 responsive_input"
                              style={{ display: "flex" }}
                            >
                              <div className="col-md-4 responsive_checkbox">
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="showroomgiga"
                                  type="radio"
                                  disabled
                                  checked={
                                    yeucaudetail.NOI_MUA_HANG ==
                                    "Tôi mua tại showroom/website của GIGA.vn"
                                  }
                                  value="Tôi mua tại showroom/website của GIGA.vn"
                                  class=" h-4 w-4"
                                />
                                <label
                                  for="showroomgiga"
                                  class=" ms-2 text-sm font-medium"
                                >
                                  Tôi mua tại showroom/website của GIGA.vn{" "}
                                </label>
                              </div>
                              <div className="col-md-8 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("SDT_MUA_HANG_SHOWROOM")}
                                  disabled
                                  value={yeucaudetail?.SDT_MUA_HANG_SHOWROOM}
                                  placeholder="Vui lòng nhập Số điện thoại mua hàng*"
                                  className="input_yeucau"
                                />
                              </div>
                            </div>{" "}
                            <br />
                            <div
                              className="col-md-12 responsive_input"
                              style={{ display: "flex" }}
                            >
                              <div className="col-md-4 responsive_checkbox">
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="tmdt"
                                  disabled
                                  type="radio"
                                  checked={
                                    yeucaudetail.NOI_MUA_HANG ==
                                    "Tôi mua qua sàn Thương mại điện tử"
                                  }
                                  value="Tôi mua qua sàn Thương mại điện tử"
                                  class=" h-4 w-4"
                                />
                                <label
                                  for="tmdt"
                                  class=" ms-2 text-sm font-medium"
                                >
                                  Tôi mua qua sàn Thương mại điện tử{" "}
                                </label>
                              </div>
                              <div className="col-md-8 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("MA_DON_HANG_TMDT")}
                                  disabled
                                  value={yeucaudetail?.MA_DON_HANG_TMDT}
                                  placeholder="Vui lòng nhập mã đơn hàng sàn Shopee, Lazada, Tiktok,...*"
                                  className="input_yeucau"
                                />
                              </div>
                            </div>{" "}
                            <br />
                            <div
                              className="col-md-12 responsive_input"
                              style={{ display: "flex" }}
                            >
                              <div className="col-md-4 responsive_checkbox">
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="matmdt"
                                  checked={
                                    yeucaudetail.NOI_MUA_HANG ==
                                    "Tôi mua qua đại lý của GIGA.vn"
                                  }
                                  type="radio"
                                  disabled
                                  value="Tôi mua qua đại lý của GIGA.vn"
                                  class=" h-4 w-4"
                                />
                                <label
                                  for="matmdt"
                                  class=" ms-2 text-sm font-medium"
                                >
                                  Tôi mua qua đại lý của GIGA.vn{" "}
                                </label>
                              </div>
                              <div className="col-md-8 responsive_checkbox">
                                <input
                                  type="text"
                                  {...register("MA_DAI_LY")}
                                  disabled
                                  value={yeucaudetail?.MA_DAI_LY_GIGA}
                                  placeholder="Vui lòng nhập mã đại lý*"
                                  className="input_yeucau"
                                />
                              </div>
                            </div>{" "}
                            <br />
                            <div
                              className="col-md-12 responsive_input"
                              style={{ display: "flex" }}
                            >
                              <div className="col-md-8 responsive_checkbox">
                                <input
                                  {...register("NOI_MUA_HANG")}
                                  id="othergift"
                                  disabled
                                  checked={
                                    yeucaudetail.NOI_MUA_HANG ==
                                    "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ"
                                  }
                                  type="radio"
                                  value="Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ"
                                  class=" h-4 w-4"
                                />
                                <label
                                  for="othergift"
                                  class=" ms-2 text-sm font-medium"
                                >
                                  Khác: Tôi được tặng/Tôi không rõ/Tôi muốn sửa
                                  dịch vụ....{" "}
                                </label>
                              </div>
                            </div>{" "}
                            <br />
                          </>
                        )}
                        {yeucaudetail.NOI_MUA_HANG == null &&
                        yeucaudetail.ROLE_USER ==
                          "Tôi là người sử dụng sản phẩm" ? (
                          <> </>
                        ) : yeucaudetail.NOI_MUA_HANG !=
                            "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ" ||
                          yeucaudetail.ROLE_USER == "Tôi là đơn vị bán lẻ" ? (
                          <>
                            <div
                              className="col-md-12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="col-md-4">
                                <label
                                  for="other"
                                  class=" ms-2 text-sm font-medium title_important"
                                >
                                  Hãy chọn Tên sản phẩm{" "}
                                  <span className="require"> *</span>:{" "}
                                </label>
                              </div>
                              <div className="col-md-8">
                                <div style={{ position: "relative" }}>
                                  <input
                                    onClick={() =>
                                      setCheckTenSanPham(!checktensanpham)
                                    }
                                    type="text"
                                    className="input_yeucau"
                                    value={
                                      yeucaudetail?.TEN_SAN_PHAM_KHACH_NHAP
                                    }
                                    {...register("TEN_SAN_PHAM_KHACH_NHAP")}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : yeucaudetail.NOI_MUA_HANG ==
                          "Tôi được tặng/Tôi không rõ/Tôi muốn sửa dịch vụ" ? (
                          <>
                            <div
                              className="col-md-12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="col-md-4">
                                <label
                                  for="other"
                                  class=" ms-2 text-sm font-medium title_important"
                                >
                                  Hãy nhập Tên sản phẩm
                                  <span className="require">*</span>:{" "}
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  disabled
                                  {...register("TEN_SAN_PHAM_KHACH_NHAP")}
                                  value={yeucaudetail?.TEN_SAN_PHAM_KHACH_NHAP}
                                  className="input_yeucau"
                                />
                              </div>
                            </div>{" "}
                          </>
                        ) : (
                          <> </>
                        )}{" "}
                        <br />
                        <div
                          className="col-md-12 responsive_input"
                          style={{ display: "flex" }}
                        >
                          <div className="col-md-4 responsive_checkbox">
                            <label
                              for="other"
                              class=" ms-2 text-sm font-medium title_important"
                            >
                              Vui lòng nhập số serial nếu có:{" "}
                            </label>
                          </div>
                          <div className="col-md-8 responsive_checkbox">
                            <input
                              disabled
                              type="text"
                              value={yeucaudetail?.SERIAL_KHACH_NHAP}
                              {...register("SERIAL_KHACH_NHAP")}
                              className="input_yeucau"
                            />
                          </div>
                        </div>{" "}
                        <br />
                      </>
                    ) : (
                      <>
                        <div className="col-md-12 responsive_input">
                          <div className="title_yeucau col-md-12">
                            Vui lòng nhập số serial của máy:{" "}
                            <span className="require">*</span>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="text"
                              {...register("SERIAL")}
                              disabled
                              className="input_yeucau"
                              value={yeucaudetail?.SERIAL}
                              placeholder="Câu trả lời của bạn"
                            />
                          </div>
                        </div>
                        <br /> <br />
                        <div
                          className="col-md-12 responsive_input"
                          style={{ display: "flex" }}
                        >
                          <div className="title_yeucau col-md-4 responsive_checkbox">
                            Tên sản phẩm
                          </div>
                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              value={yeucaudetail?.TEN_SAN_PHAM}
                              disabled
                              readOnly
                              className="input_yeucau"
                            />
                          </div>
                        </div>{" "}
                        <br />
                        <div
                          className="col-md-12 responsive_input"
                          style={{ display: "flex" }}
                        >
                          <div className="title_yeucau col-md-4 responsive_checkbox">
                            Hạn bảo hành
                          </div>
                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              disabled
                              readOnly
                              {...register("HAN_BAO_HANH")}
                              className="input_yeucau"
                              value={yeucaudetail?.HAN_BAO_HANH}
                            />
                          </div>
                        </div>{" "}
                        <br />
                        <div
                          className="col-md-12 responsive_input"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="col-md-4 responsive_checkbox"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              {...register("IS_SUA_CHUA_CO_PHI")}
                              checked={
                                yeucaudetail?.IS_SUA_CHUA_CO_PHI == "true"
                              }
                              id="checked-checkbox"
                              disabled
                              type="checkbox"
                              defaultValue=""
                              className="bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded text-blue-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                            />
                            <label
                              htmlFor="checked-checkbox"
                              className="text-gray-900 dark:text-gray-300 ms-2 text-sm font-medium"
                            >
                              Tôi cần sửa chữa dịch vụ có phí
                            </label>
                          </div>

                          <div className="col-md-8 responsive_checkbox">
                            <input
                              type="text"
                              {...register("TEN_SAN_PHAM_KHACH_NHAP")}
                              disabled
                              value={yeucaudetail?.TEN_SAN_PHAM_KHACH_NHAP}
                              className="input_yeucau"
                              placeholder="Vui lòng nhập tên sản phẩm của bạn"
                            />
                          </div>
                        </div>{" "}
                        <br />
                      </>
                    )}
                    <div className="col-md-12">
                      <div>
                        Máy của bạn đang gặp sự cố gì, hãy mô tả thật kỹ để
                        chúng tôi hỗ trợ tốt nhất cho bạn nhé{" "}
                        <span className="require">*</span>:
                      </div>
                      <div className="my-2">
                        <textarea
                          disabled
                          {...register("MO_TA_LOI_CHO_KY_THUAT")}
                          value={yeucaudetail?.MO_TA_LOI_CHO_KY_THUAT}
                          style={{
                            height: "130px",
                            outline: "none",
                            width: "100%",
                            borderRadius: "6px",
                          }}
                          class="border-b border-none p-2.5 text-sm "
                        />
                      </div>
                    </div>
                    <div>
                      <input
                        type="button"
                        value={"Hiển thị hình ảnh"}
                        onClick={showModalImage}
                      />
                    </div>
                    <div className="col-md-12 prestep_button">
                      <button
                        className=" mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold rounded-md  shadow-lg "
                        style={{ marginRight: "5px" }}
                        onClick={() => setCurrentTab(2)}
                      >
                        Quay lại
                      </button>

                      <button
                        type="submit"
                        className=" mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold shadow-lg hover:border-transparent"
                      >
                        Tiếp
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img
                      alt="Hình ảnh"
                      style={{ height: "20rem" }}
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src={imageBanner1}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
          {/* ------ */}
          {/* Tab 4 */}
          <form onSubmit={handleSubmit(onSubmitForm4)}>
            {currentTab == "4" && (
              <div className="Tab 4">
                <h5 className="fw-bold text-center title_important ">
                  TẠO PHIẾU YÊU CẦU BẢO HÀNH - SỬA CHỮA ONLINE
                </h5>
                <div className="content row progress_step">
                  <div className="col-3" id="comment_bubblefirst">
                    Thông tin liên hệ
                  </div>
                  <div id="comment_bubble" className="col-3">
                    Thông tin sản phẩm
                  </div>
                  <div id="comment_bubble" className="col-3">
                    Thông tin vận chuyển
                  </div>
                </div>
                <div className="content row ">
                  <div className="col-sm-10">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">
                        <p className="title_important_header">
                          3. THÔNG TIN VẬN CHUYỂN
                        </p>
                      </li>
                    </ul>
                    <div className="col-md-12 form-inline title_yeucau">
                      Bạn muốn gửi sản phẩm đến trung tâm bảo hành nào?
                      <span className="require">*</span>
                    </div>{" "}
                    <br />
                    {listTTBH?.map((item) => (
                      <>
                        <div
                          className="col-md-12"
                          style={{ display: "flex", alignItems: "center" }}
                          key={item.id}
                        >
                          <input
                            {...register("TRUNG_TAM_BAO_HANH")}
                            id={item.$id}
                            type="radio"
                            disabled
                            value={item.Key}
                            checked={
                              yeucaudetail?.TRUNG_TAM_BAO_HANH == item.Key
                            }
                            className="h-4 w-4"
                          />
                          <label
                            htmlFor={item.$id}
                            className="ms-2 text-sm font-medium"
                          >
                            {item.Value}
                          </label>
                        </div>

                        <br />
                      </>
                    ))}
                    <br />
                    <div className="col-md-12 form-inline title_yeucau">
                      Hãy sau khi hoàn tất dịch vụ, bạn muốn chúng tôi gửi trả
                      sản phẩm về địa chỉ nào?<span className="require">*</span>
                    </div>
                    <br />
                    <div
                      className="col-md-12"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        {...register("GUI_HANG_VE_DIA_CHI")}
                        id="tructiep"
                        type="radio"
                        disabled
                        checked={
                          yeucaudetail?.GUI_HANG_VE_DIA_CHI ==
                          "Tôi sẽ qua Trung tâm bảo hành nhận trực tiếp"
                        }
                        value="Tôi sẽ qua Trung tâm bảo hành nhận trực tiếp"
                        className=" h-4 w-4"
                      />
                      <label for="tructiep" class=" ms-2 text-sm font-medium">
                        Tôi sẽ qua Trung tâm bảo hành nhận trực tiếp{" "}
                      </label>
                    </div>{" "}
                    <br />
                    <div
                      className="col-md-12 responsive_input"
                      style={{ display: "flex" }}
                    >
                      <div className="col-md-3 responsive_checkbox">
                        <input
                          {...register("GUI_HANG_VE_DIA_CHI")}
                          id="Tôi muốn gửi về địa chỉ"
                          disabled
                          type="radio"
                          value="Tôi muốn gửi về địa chỉ"
                          checked={
                            yeucaudetail?.GUI_HANG_VE_DIA_CHI ==
                            "Tôi muốn gửi về địa chỉ"
                          }
                        />
                        <label for="Tôi muốn gửi về địa chỉ">
                          Tôi muốn gửi về địa chỉ:{" "}
                        </label>
                      </div>
                      <div className="col-md-3 responsive_checkbox">
                        <div className="city" style={{ position: "relative" }}>
                          <input
                            type="text"
                            className="form-control "
                            disabled
                            {...register("TINH_THANH_PHO")}
                            placeholder="Tỉnh/Thành phố"
                            value={yeucaudetail?.TINH_THANH_PHO}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 responsive_checkbox">
                        <div className="city" style={{ position: "relative" }}>
                          <input
                            type="text"
                            className="form-control "
                            disabled
                            {...register("QUAN_HUYEN")}
                            placeholder="Tỉnh/Thành phố"
                            value={yeucaudetail?.QUAN_HUYEN}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 responsive_checkbox">
                        <div className="city" style={{ position: "relative" }}>
                          <input
                            type="text"
                            className="form-control "
                            disabled
                            {...register("PHUONG_XA")}
                            placeholder="Tỉnh/Thành phố"
                            value={yeucaudetail?.PHUONG_XA}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-form-label col-md-2">
                        Địa chỉ{" "}
                      </label>

                      <input
                        disabled
                        value={yeucaudetail?.DIA_CHI_GUI_HANG_CHO_KHACH}
                        className="input_yeucau"
                        {...register("DIA_CHI_GUI_HANG_CHO_KHACH")}
                      />
                    </div>
                    <br />
                    <div
                      className="col-md-12"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        {...register("GUI_HANG_VE_DIA_CHI")}
                        id="other"
                        disabled
                        type="radio"
                        value="Tôi sẽ thông báo sau"
                        checked={
                          yeucaudetail?.GUI_HANG_VE_DIA_CHI ==
                          "Tôi sẽ thông báo sau"
                        }
                      />
                      <div className="mt-1 ml-1" for="other">
                        Tôi sẽ thông báo sau:{" "}
                      </div>
                    </div>{" "}
                    <br />
                    <div className="col-md-12">
                      <div>Bạn cần chúng tôi lưu ý điều gì không?</div>
                      <div className="my-2">
                        <textarea
                          disabled
                          {...register("LUU_Y_TU_KHACH_HANG")}
                          value={yeucaudetail?.LUU_Y_TU_KHACH_HANG}
                          style={{
                            height: "130px",
                            outline: "none",
                            width: "100%",
                            borderRadius: "6px",
                          }}
                          class="border-b border-none p-2.5 text-sm "
                        />
                      </div>
                    </div>
                    <div className="col-md-12 prestep_button">
                      <button
                        className=" mb-3 rounded  border  bg-transparent px-4 py-2  font-semibold rounded-md shadow-lg "
                        style={{ marginRight: "5px" }}
                        onClick={() => setCurrentTab(3)}
                      >
                        Quay lại
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-2 csbh_image_banner ">
                    <img
                      alt="Hình ảnh"
                      style={{ height: "20rem" }}
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src={imageBanner1}
                    />
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>

        <Modal
          title="Hiển thị hình ảnh"
          open={isModalOpen}
          width={1000}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {yeucaudetail?.listFiles.map((item) => (
            <div className="grid-container-image">
              <div
                onClick={() => handleImageClick(item.FILE_NAMES)}
                className="grid-item-image"
              >
                <img
                  src={item.FILE_NAMES}
                  alt="Image"
                  style={{ width: "100%", height: "200px" }}
                />
                <div className="text-center">
                  {formatDate(item.DATE_UPLOAD)}
                </div>
              </div>
            </div>
          ))}

          {isLightboxVisible && (
            <div
              className="lightbox"
              onClick={() => setIsLightboxVisible(false)}
            >
              <img src={zoomImage} alt="Zoomed Image" />
            </div>
          )}
          <style jsx>{`
            .lightbox {
              display: ${isLightboxVisible ? "block" : "none"};
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.8);
              text-align: center;
              cursor: pointer;
              animation: zoom-in-zoom-out 1s ease;
            }

            .lightbox img {
              max-width: 90%;
              max-height: 60%;
              margin-top: 5%;
            }

            @keyframes zoom-in-zoom-out {
              0% {
                transform: scale(0.5, 0.5);
              }

              100% {
                transform: scale(1, 1);
              }
            }
          `}</style>
        </Modal>
      </div>
    </>
  );
};

export default YeuCauDetail;
