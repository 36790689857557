import { SET_CAPTCHA, SET_LOADING } from "../type";

export const setLoading = (value) => async (dispatch) => {
  try {
    return dispatch({ type: SET_LOADING, data: value });
  } catch (error) {
    return error;
  }
};

export const setCaptcha = (value) => async (dispatch) => {
  try {
    return dispatch({ type: SET_CAPTCHA, data: value });
  } catch (error) {
    return error;
  }
};
