import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import BannerImage from '../../assets/image/banner-image.jpg'
import { danhSachSanPham } from "../../redux/category/action"
import { _danhSachPhuKien } from "../../api/baoHanh"
import { checkNumber } from "../../utilities/checkImage"
import Paginations from "../layout/pagination"

const idEcovacs = '62c80aa3fe1131cf8fd018f4'

const ProductSelector = () => {

  const dispatch = useDispatch()

  const danhSachSanPhamEcovacs = useSelector(store => store.categories.danhSachSanPhamEcovacs)

  const [selectedCheckbox, setSelectedCheckbox] = useState(null)
  const [danhSachPhuKienEcovacs, setDanhSachPhuKienEcovacs] = useState([])
  const [fetchDataFlag, setFetchDataFlag] = useState(false)

  const [modalListEcovacs, setModalListEcovacs] = useState(false)
  const [showDetail, setShowDetail] = useState(null)

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(12)
  const [totalProducts, setTotalProducts] = useState(0)
  const [filterProducts, setFilterProducts] = useState(false)

  useEffect(() => {
    dispatch(danhSachSanPham(idEcovacs))
  }, [])

  useEffect(() => {
    if (!fetchDataFlag && danhSachSanPhamEcovacs.length > 0) {
      const firstProduct = danhSachSanPhamEcovacs[0]?.product[0]
      if (firstProduct) {
        setSelectedCheckbox(firstProduct.slug)
        handleProduct(firstProduct.slug)
        setFetchDataFlag(true)
      }
    }
  }, [fetchDataFlag, danhSachSanPhamEcovacs])

  useEffect(() => {
    if (selectedCheckbox) {
      handleProduct(selectedCheckbox)
    }
  }, [page])

  const handleFilterProducts = () => {
    setFilterProducts(!filterProducts)
  }

  const handleProduct = (product) => {
    setSelectedCheckbox(product)
    const params = {
      slugProduct: product,
      page: page,
      per_page: perPage
    }
    _danhSachPhuKien(params)
      .then(res => {
        setDanhSachPhuKienEcovacs(res.danhSachSanPham)
        setTotalProducts(res.totalProducts)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }

  const toggleModalListEcovacs = (value) => {
    setModalListEcovacs(!modalListEcovacs)
    setShowDetail(value)
  }

  return (
    <div className="product-selector">
      <div className="banner-image position-relative">
        <figure className="image img-fluid ">
          <img src={BannerImage} className="w-100" />

        </figure>
        <div className="container">
          <h2 className="position-absolute text-white">Phụ kiện dành cho Robot hút bụi</h2>
        </div>
      </div>
      <div className="container">
          <div className="row mt-4">
            <div className="col-md-3 d-md-block d-none">
            {
                danhSachSanPhamEcovacs.map((item, index) => (
                  <div className="list-group">
                    <h5 className="text-primary fw-bold">{item.name}</h5>
                    <div className="group-series">
                      {item.product.map((ele) => (
                        <div key={ele._id} className="list-group-item border-0 d-flex">
                          <input
                            type="checkbox"
                            id={ele.slug}
                            onChange={() => handleProduct(ele.slug)}
                            checked={selectedCheckbox === ele.slug}
                          />
                          <label htmlFor={ele.slug} className="ms-2">{ele.name_ecovacs}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="col-md-3 d-md-none d-block mb-3">
              <button className="btn bg-transparent border-1 d-block d-md-none" onClick={handleFilterProducts}>
                <i className="fa fa-search me-2"></i> Tìm kiếm
              </button>
              {
                filterProducts && danhSachSanPhamEcovacs.map((item, index) => (
                  <div className="list-group">
                    <h5 className="text-primary fw-bold">{item.name}</h5>
                    <div className="group-series">
                      {item.product.map((ele) => (
                        <div key={ele._id} className="list-group-item border-0 d-flex">
                          <input
                            type="checkbox"
                            id={ele.slug}
                            onChange={() => handleProduct(ele.slug)}
                            checked={selectedCheckbox === ele.slug}
                          />
                          <label htmlFor={ele.slug} className="ms-2">{ele.name_ecovacs}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="col-md-9">
              <div className="product-item-selector">
                {danhSachPhuKienEcovacs.length > 0 ?
                  (danhSachPhuKienEcovacs.map((item, index) => (
                    <div key={index} className="list-item-selector">
                      <div className="card-product-selector">
                        <div className='d-item d-inline-block'>
                          <img src={`https://img.gigadigital.vn/image/${item.thumb_ecovacs}`} className='w-100' loading='lazy' alt={item.thumb_ecovacs} />
                        </div>
                        <div className='info-product p-2'>
                          <div className="info-detail">
                            <h5 className='mb-2'>
                              <div>
                                {item.name_ecovacs}
                              </div>
                            </h5>
                            <p className='box-price'><span className='price me-2 text-red'>{item.price === 0 ? 'Liên hệ' : checkNumber(item.price)}</span><span className='price-old text-gray text-decoration-line-through'>{item.giaList}</span></p>
                          </div>

                          <div className="box-detail">
                            <Link key={item.id} className='item-news' onClick={() => toggleModalListEcovacs(item)}>
                              <p className="text-detail">Thông số <span>kỹ thuật</span> </p>
                            </Link>

                            {/* <a className="border-0 ms-3">
                              <span className="fa fa-shopping-cart"></span>
                            </a> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  ))
                  ) : (
                    <h6>Không có phụ kiện</h6>
                  )}
                {
                  modalListEcovacs && (
                    <Modal centered isOpen={modalListEcovacs} toggle={toggleModalListEcovacs} size='lg' className='modal-detail'>
                      <ModalHeader toggle={toggleModalListEcovacs} tag="h5" className='modal-thongso'>Thông số kĩ thuật</ModalHeader>
                      <ModalBody className=''>
                        {
                          (ReactHtmlParser(showDetail.cau_hinh_chi_tiet))
                        }
                      </ModalBody>
                    </Modal>
                  )
                }
              </div>
              <div className='w-100 my-3'>
                <Paginations page={page} setPage={setPage} perPage={perPage} totalItem={totalProducts} />
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ProductSelector