// Homepage
import CheckBaoHanh from "../components/home/index";
import KichHoat from "../components/view/kichHoat";
import TraCuu from "../components/view/traCuu";
import YeuCau from "../components/view/yeuCau";
import TramBaoHanh from "../components/view/tramBaoHanh";
import HuongDan from "../components/view/huongDan";
import TinTuc from "../components/baoHanh/tinTuc";
import SanPham from "../components/baoHanh/sanPham";
import ChiTietTinTuc from "../components/baoHanh/tinTuc";

import TraCuuBaoHanhMoi from "../components/view/traCuuBaoHanh";
import PhieuBaoHanh from "../components/view/phieuBaoHanh";
import ProductSelector from "../components/view/productSelector";
import TraCuuTinhTrangBaoHanh from "../components/view/tracuutinhtrangbaohanh";
import YeuCauDetail from "../components/view/yeucaudetail";

export const routes = [
  { path: "/", Component: CheckBaoHanh },
  { path: "/-hanh", Component: KichHoat },
  { path: "/tra-cuu-bao-hanh", Component: TraCuu },
  { path: "/gui-yeu-cau", Component: YeuCau },
  { path: "/tram-bao-hanh", Component: TramBaoHanh },
  { path: "/tra-cuu", Component: TraCuuBaoHanhMoi },
  { path: "/san-pham/:id", Component: SanPham },
  { path: "/tin-tuc/:id", Component: ChiTietTinTuc },
  { path: "/san-pham/:id", Component: SanPham },
  { path: "/phieu-bao-hanh/:id", Component: PhieuBaoHanh },
  { path: "/product-selector", Component: ProductSelector },
  { path: "/chinh-sach-bao-hanh", Component: HuongDan },
  { path: "/tra-cuu-tinh-trang-bao-hanh", Component: TraCuuTinhTrangBaoHanh },
  { path: "/gui-yeu-cau/:id", Component: YeuCauDetail },
];
