import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { _kichHoatBaoHanh } from "../../api/baoHanh";
import { toast } from "react-toastify";
import { Space, Spin, Table, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCity,
  getDistrict,
  getMaDaiLyBan,
  getProductName,
  getThuongHieu,
  getWard,
  kichHoatBaoHanh,
} from "../../redux/category/action";
import { findIndex } from "lodash";
const KichHoat = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [tableBank, setTableBank] = useState(false);
  const [thanhphoModal, setThanhphoModal] = useState(false);

  const listCity = useSelector((store) => store.categories.listCity);
  const listDistrict = useSelector((store) => store.categories.listDistrict);
  const listWard = useSelector((store) => store.categories.listWard);

  const listThuongHieu = useSelector(
    (store) => store.categories.listThuongHieu
  );
  const tensanpham = useSelector((store) => store.categories.getProductName);
  const resultkichhoat = useSelector(
    (store) => store.categories.getKichHoatBaoHanh
  );
  const resultMaDaiLyBan = useSelector(
    (store) => store.categories.getMaDaiLyBan
  );
  const [tenThuongHieu, setTenThuongHieu] = useState("");
  const [serial, setSerial] = useState("");
  const [date, setDate] = useState("");

  const [city, setCity] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [district, setDistrict] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [ward, setWard] = useState(null);
  const [wardId, setWardId] = useState(null);

  const [maDailyBan, setMaDailyBan] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const [textErrorMessage, setTextErrorMessage] = useState("");

  const [filteredCities, setFilteredCities] = useState(listCity);
  const [filterText, setFilterText] = useState("");

  const [textErrorMessageKichHoat, setTextErrorMessageKichHoat] = useState("");

  const [loading, setisLoading] = useState(false);

  useEffect(() => {
    dispatch(getCity());
    dispatch(getThuongHieu(""));
  }, []);

  useEffect(() => {
    if (resultkichhoat) {
      if (!resultkichhoat.status) {
        setTextErrorMessageKichHoat(resultkichhoat.message);
      } else if (resultkichhoat.status) {
        setDisableButton(false);
        setTextErrorMessageKichHoat(resultkichhoat.message);
      }
    }
  }, [resultkichhoat]);

  useEffect(() => {
    if (resultMaDaiLyBan && resultMaDaiLyBan.length > 0) {
      setTextErrorMessage("");
      setDisableButton(true);
    } else if (maDailyBan == "") {
      setTextErrorMessage("");
    } else {
      setTextErrorMessage(
        "Mã đại lý không chính xác, vui lòng kiểm tra lại hoặc liên hệ nhân viên bán hàng để đc trợ giúp."
      );
      setDisableButton(false);
    }
  }, [resultMaDaiLyBan, textErrorMessage]);

  useEffect(() => {
    if (filterText != "") {
      dispatch(getDistrict(cityId));
    }
  }, [cityId]);

  useEffect(() => {
    if (districtId != null) {
      dispatch(getWard(districtId));
    }
  }, [districtId]);

  useEffect(() => {
    findIndex(listCity, (item) => {
      if (item.ProvinceName == city) {
        setCityId(item.ProvinceID);
        setDistrict("");
      }
    });
  }, [city]);

  useEffect(() => {
    findIndex(listDistrict, (item) => {
      if (item.DistrictName == district) {
        setDistrictId(item.DistrictID);
      }
    });
  }, [district]);

  useEffect(() => {
    findIndex(listWard, (item) => {
      if (item.WardName == ward) {
        setWardId(item.DistrictID);
      }
    });
  }, [ward]);

  const onSubmit = async (data) => {
    const body = {
      Serial: serial,
      MA_KH_KICH_H0AT: maDailyBan,
      NGAY_MUA_HANG: date,
      MA_NHOM_HANG: tenThuongHieu,
      SO_DIEN_THOAI: data.SO_DIEN_THOAI,
      HO_VA_TEN: data.HO_VA_TEN,
      DIA_CHI: data.DIA_CHI,
      THANH_PHO: data.city,
      QUAN_HUYEN: data.district,
      PHUONG_XA: data.ward,
      USER_NAME: "",
    };

    await dispatch(kichHoatBaoHanh(body));
  };

  const columnsBank = [
    {
      title: "",
      dataIndex: "MA_NHOM_HANG",
      key: "MA_NHOM_HANG",
      render: (file_path_PI, record, index) => {
        return (
          <input
            value={record.MA_NHOM_HANG}
            name="MA_NHOM_HANG"
            onClick={() => {
              setTenThuongHieu(record.MA_NHOM_HANG);
              setTableBank(false);
            }}
            style={{
              width: "100%",
              border: "none",
              background: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
            readOnly
          />
        );
      },
    },
  ];

  const columnsCity = [
    {
      title: "",
      dataIndex: "ProvinceName",
      key: "ProvinceName",
      render: (file_path_PI, record, index) => {
        return (
          <input
            value={record.ProvinceName}
            name="ProvinceName"
            onClick={() => {
              setFilterText(record.ProvinceName);
              setCityId(record.ProvinceID);
            }}
            style={{
              width: "100%",
              border: "none",
              background: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
            readOnly
          />
        );
      },
    },
  ];
  useEffect(() => {
    if (serial && tenThuongHieu) {
      dispatch(getProductName(serial, tenThuongHieu));
    }
  }, [tenThuongHieu, serial]);

  const onChange = async (event) => {
    var value = event.target.value;
    var name = event.target.name;
    if (value != null && name == "thuonghieu") {
      await setTenThuongHieu(value);
      await dispatch(getThuongHieu(value));
    }
    if (name == "serial") {
      setSerial(value);
    }
    if (name == "madaily") {
      setMaDailyBan(value);
      setisLoading(true);
    }
    if (name == "city") {
      setFilterText(value);
      filterCities(value);
    }
  };

  const onChangeDatetime = (date, dateString) => {
    setDate(dateString);
  };

  const handleChangeDistrict = (e) => {
    setDistrict(e.target.value);
  };

  const handleChangeWard = (e) => {
    setWard(e.target.value);
  };

  const onBlur = async (event) => {
    var value = event.target.value;
    var name = event.target.name;
    if (name == "thuonghieu") {
      setTimeout(() => {
        setTableBank(false);
      }, 200);
    }
    if (name == "madaily") {
      setisLoading(false);
      setMaDailyBan(value);
      await dispatch(getMaDaiLyBan(value));
    }
    if (name == "city") {
      setTimeout(() => {
        setThanhphoModal(false);
      }, 200);
    }
  };

  const filterCities = (criteria) => {
    const filtered = listCity.filter((city) =>
      city.ProvinceName.toLowerCase().includes(criteria.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  return (
    <div className="kich-hoat container">
      <div className="text-center col-md-12">
        <h3 className="text-uppercase fw-bold">Kích hoạt bảo hành</h3>
        <h6 className="fw-bold">
          Kích hoạt bảo hành cho sản phẩm của Giga digital bằng Serial
        </h6>
      </div>
      <div
        className="col-md-8 mt-4 d-flex justify-content-between"
        style={{ margin: "0 auto" }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row justify-content-between"
        >
          <div className="col-md-6 w-45">
            <h5 className="mb-3">Thông tin sản phẩm</h5>
            <div className="form-group row">
              <label className="col-form-label col-md-4">
                Số Serial <span className="require">(*)</span>
              </label>
              <div className="col-md-8">
                <input
                  className="form-control"
                  required
                  name="serial"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-form-label col-md-4">
                Thương hiệu <span className="require">(*)</span>
              </div>
              <div style={{ position: "relative" }} className="col-md-8">
                <input
                  onClick={() => setTableBank(!tableBank)}
                  className="form-control"
                  name="thuonghieu"
                  required
                  onBlur={onBlur}
                  value={tenThuongHieu}
                  onChange={onChange}
                />
                {tableBank && (
                  <Table
                    columns={columnsBank}
                    dataSource={listThuongHieu}
                    pagination={false}
                    scroll={{ y: 300 }}
                    style={{
                      position: "absolute",
                      top: 50,
                      zIndex: "1",
                      border: "1px solid #dee2e6",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-4">Tên sản phẩm</label>
              <div className="col-md-8">
                <textarea
                  disabled
                  value={
                    tensanpham && tensanpham.length > 0
                      ? tensanpham[0]?.TEN_HANG ?? ""
                      : ""
                  }
                  className="form-control"
                  {...register("TEN_SAN_PHAM")}
                />
                {tensanpham == "" && (
                  <span className="require">
                    Serial bạn nhập không tồn tại, vui lòng kiểm tra lại hoặc
                    liên hệ hotline 1900.633.870 để đc trợ giúp
                  </span>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-4">
                Mã đại lý bán <span className="require">(*)</span>
              </label>
              <div className="col-md-8" style={{ position: "relative" }}>
                <input
                  className="form-control"
                  name="madaily"
                  style={{
                    border: `1px solid ${
                      !disableButton && textErrorMessage === ""
                        ? "#dee2e6"
                        : disableButton
                        ? "green"
                        : "red"
                    }`,
                  }}
                  value={maDailyBan}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder="KHGD......"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 10,
                    display: loading ? "" : "none",
                  }}
                >
                  {" "}
                  <Space>
                    {" "}
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      size="small"
                    />{" "}
                  </Space>{" "}
                </div>
                <span className="require">{textErrorMessage} </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-4">
                Ngày mua <span className="require">(*)</span>
              </label>
              <div className="col-md-8">
                <DatePicker
                  required
                  style={{ width: "100%" }}
                  onChange={onChangeDatetime}
                  format={"DD/MM/YYYY"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 w-45">
            <h5 className="mb-3">Thông tin khách hàng</h5>
            <div className="form-group row">
              <label className="col-form-label col-md-4">
                Họ tên <span className="require">(*)</span>
              </label>
              <div className="col-md-8">
                <input
                  className="form-control"
                  {...register("HO_VA_TEN", {
                    required: "Không được để trống",
                  })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-4">
                Số điện thoại <span className="require">(*)</span>
              </label>
              <div className="col-md-8">
                <input
                  className="form-control"
                  {...register("SO_DIEN_THOAI", {
                    required: "Không được để trống",
                  })}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-4">
                {" "}
                Tỉnh / Thành phố{" "}
              </label>
              <div style={{ position: "relative" }} className="col-md-8">
                <input
                  onClick={() => setThanhphoModal(!thanhphoModal)}
                  type="text"
                  className="form-control"
                  name="city"
                  placeholder="Nhập để lọc tỉnh/thành phố"
                  value={filterText}
                  onBlur={onBlur}
                  onChange={onChange}
                />
                {thanhphoModal && (
                  <Table
                    columns={columnsCity}
                    dataSource={
                      filteredCities.length == 0 ? listCity : filteredCities
                    }
                    pagination={false}
                    scroll={{ y: 300 }}
                    style={{
                      position: "absolute",
                      top: 50,
                      zIndex: "1",
                      border: "1px solid #dee2e6",
                    }}
                  />
                )}

                {errors.city && (
                  <p className="text-danger">{errors.city.message}</p>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-4">Quận/Huyện</label>
              <div className="col-md-8">
                <select
                  className="form-control"
                  {...register("district")}
                  value={district}
                  onChange={(e) => handleChangeDistrict(e)}
                >
                  <option value={""} selected disabled>
                    Quận/Huyện
                  </option>
                  {listDistrict &&
                    listDistrict.map((item, index) => (
                      <option key={index} value={item.DistrictName}>
                        {item.DistrictName}
                      </option>
                    ))}
                </select>
                {errors.district && (
                  <p className="text-danger">{errors.district.message}</p>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-4">Phường/Xã</label>
              <div className="col-md-8">
                <select
                  className="form-control"
                  {...register("ward")}
                  value={ward}
                  onChange={(e) => handleChangeWard(e)}
                >
                  <option value={""} selected disabled>
                    Phường/Xã
                  </option>
                  {listWard &&
                    listWard.map((item, index) => (
                      <option key={index} value={item.WardName}>
                        {item.WardName}
                      </option>
                    ))}
                </select>
                {errors.ward && (
                  <p className="text-danger">{errors.ward.message}</p>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-4">Địa chỉ </label>
              <div className="col-md-8">
                <input className="form-control" {...register("DIA_CHI")} />
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button
              disabled={!disableButton}
              className="btn btn-primary mt-3 d-inline-block"
            >
              Kích hoạt bảo hành
            </button>
          </div>
          <div className="mt-2">
            <>
              <div className="container-fluid text-center">
                {resultkichhoat?.status === undefined ? (
                  <> </>
                ) : !resultkichhoat?.status ? (
                  <div className="new-message-box">
                    <div className="new-message-box-danger">
                      <div className="info-tab tip-icon-danger" title="error">
                        <i />
                      </div>
                      <div className="tip-box-danger">
                        <p>{textErrorMessageKichHoat}</p>
                        <p> Cảm ơn Quý khách!</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="new-message-box">
                    <div className="new-message-box-success">
                      <div
                        className="info-tab tip-icon-success"
                        title="success"
                      >
                        <i />
                      </div>
                      <div className="tip-box-success">
                        <p>
                          Kích hoạt thành công, Quý khách vui lòng tra cứu thời
                          hạn bảo hành tại link:{" "}
                          <a href="https://baohanh.gigarobotics.vn/thoi-han-bao-hanh">
                            {" "}
                            https://baohanh.gigarobotics.vn/thoi-han-bao-hanh.{" "}
                          </a>{" "}
                          Cảm ơn quý khách đã ủng hộ Giga.vn!
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default KichHoat;
