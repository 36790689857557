
import React, { useState, useEffect } from "react"
import ReactHtmlParser from 'react-html-parser'

import { getCityEcovacs, getListTTBaoHanh } from "../../redux/category/action"
import { useDispatch, useSelector } from "react-redux"

// export const addressStore = [
//   {
//     _id: 1,
//     city: 'Hà Nội',
//     slug: 'ha-noi',
//     store: [
//       {
//         address: '55 Thái Hà, Đống Đa',
//         link: '/chi-nhanh/ha-noi',
//         map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.5252429801017!2d105.81907381540219!3d21.011659693736632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab70ef9c0da9%3A0x3115ff2b1e823b92!2sGiga%20Digital!5e0!3m2!1svi!2s!4v1666603038672!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
//       }
//     ]
//   },
//   {
//     _id: 2,
//     city: 'Hải Phòng',
//     slug: 'hai-phong',
//     store: [
//       {
//         address: 'Số nhà 23 - BS1 Khu Đô thị PG, An Đồng, An Dương',
//         link: '/chi-nhanh/hai-phong',
//         map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7457.398130338945!2d106.64572747671313!3d20.843855171086314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a7a0c63305a87%3A0xac2e071a95be88aa!2zS2h1IMSRw7QgdGjhu4sgUEcsIEFuIMSQ4buTbmcsIEFuIETGsMahbmcsIEjhuqNpIFBow7JuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1663987065665!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
//       }
//     ]
//   },
//   {
//     _id: 3,
//     city: 'Nam Định',
//     slug: 'nam-dinh',
//     store: [
//       {
//         address: '81 Lê Hồng Phong, P. Phan Đình Phùng \n Thành phố Nam Định, Nam Định',
//         link: '/chi-nhanh/nam-dinh',
//         map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d233.68205364115826!2d106.17841643213313!3d20.427666931231816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135e78896ae4b57%3A0xe9d749a8e8d956ea!2sGiga%20Digital!5e0!3m2!1svi!2s!4v1666583049627!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
//       }
//     ]
//   },
//   {
//     _id: 4,
//     city: 'Đà Nẵng',
//     slug: 'da-nang',
//     store: [
//       {
//         address: '35 Chu Mạnh Trinh, Cẩm Lệ',
//         link: '/chi-nhanh/da-nang',
//         map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.5969294527854!2d108.21091811536087!3d16.034484544626007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219933b3b9407%3A0xfb715e2adecce62d!2zMzUgQ2h1IE3huqFuaCBUcmluaCwgS2h1w6ogVHJ1bmcsIEPhuqltIEzhu4csIMSQw6AgTuG6tW5nIDU1MDAwMCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1663987006158!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
//       }
//     ]
//   },
//   {
//     _id: 5,
//     city: 'Hồ Chí Minh',
//     slug: 'ho-chi-minh',
//     store: [
//       {
//         address: `Minh Phụng, Tân Thới Nhất, Quận 12, HCM \n (cạnh chung cư Prosper Plaza)`,
//         link: '/chi-nhanh/ho-chi-minh',
//         map: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d489.8439508919879!2d106.61923184547088!3d10.830311279016675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752bcaef798773%3A0xf4655b9a75103ec8!2sGiga%20Digital!5e0!3m2!1svi!2s!4v1666582661999!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
//       }
//     ]
//   }
// ]

const TramBaoHanh = () => {

  const dispatch = useDispatch()

  const listCityBaoHanh = useSelector(store => store.categories.listCityBaoHanh)

  const [city, setCity] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(getCityEcovacs())
  }, [])

  useEffect(() => {
    if(listCityBaoHanh.LtsItem) {
      setCity(listCityBaoHanh.LtsItem[0].ID)
    }
  }, [listCityBaoHanh])

  return (
    <div className="container tram-bao-hanh">
      <div className="title text-center">
        <h4 className="py-3 mb-0 text-uppercase"><strong>Trung tâm bảo hành CỦA ECOVACS</strong></h4>
      </div>
      <div className='map-search mb-3'>
      <div className="d-flex align-items-center">
       
        <div className="d-flex align-items-center w-30">
        <select className="form-control"
          onChange={(e) => setCity(e.target.value)}
        >
          <option selected disabled>Tỉnh/thành phố</option>
          {
            listCityBaoHanh.LtsItem && listCityBaoHanh.LtsItem.map((item, index) => (
              <option key={index} value={item.ID}>{item.Title}</option>
            ))
          }
        </select>
        </div>
      </div>
      </div>
      {
        listCityBaoHanh && (
          <Item city={city}/>
        )
      }
    </div>
  )
}

export default TramBaoHanh

const Item = ({city}) => {

  const dispatch = useDispatch()

  const listTTBaoHanh = useSelector(store => store.categories.listTTBaoHanh)

  const [address, setAddress] = useState('')

  useEffect(() => {
    dispatch(getListTTBaoHanh(city))
  }, [city])

  useEffect(() => {
    if(listTTBaoHanh.length > 0) {
      setAddress(listTTBaoHanh[0].link_address)
    }
  }, [listTTBaoHanh])

  return (
    <div className="content">
      <div className="content-info">
        {
          listTTBaoHanh.map((item, index) => (
            <div key={index} className='d-block item-list-map'>
              <p><strong >{item.ten_chi_nhanh}</strong></p>
              <div className='d-flex align-items-center justify-content-between'>
                <p><i className="fa fa-map-marker mr-10 float-left text-primary"></i><span className="d-flex" dangerouslySetInnerHTML={{ __html: item.address.replace(/\n/g, "<br />") }}></span></p>
                <button type="button" className="border-0 bg-transparent text-primary" onClick={() => setAddress(item.link_address)}>Xem bản đồ</button>
              </div>
            </div>
          ))
        }
      </div>

      <div className="vertical-line"></div>
      <div className="content-map">
        {
          address != '' &&
          <p>
            {
              ReactHtmlParser(address && address)
            }
          </p>
        }
      </div>
    </div>
  )
}