import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { getProductEcovacsId } from "../../redux/category/action"
import ReactHtmlParser from 'react-html-parser'
import moment from "moment"
import { checkImageNews } from "../../utilities/checkImage"

const SanPham = () => {

  const dispatch = useDispatch()

  const { id } = useParams()

  const productEcovacsId = useSelector(store => store.categories.productEcovacsId)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getProductEcovacsId(id))
  }, [id])

  return (
    <div className="container">
      <div className="detail-product">
        <div className="content-product mt-4">
          <div className="head-product justify-content-between">
            <h2 className="fw-500">{productEcovacsId?.product_name}</h2>
            <img src={checkImageNews(productEcovacsId?.avatar)} className='img-fluid' />
          </div>
          <div className="detail-new mt-3">
            {
              ReactHtmlParser(productEcovacsId?.product_description)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SanPham