import {
  GET_CATEGORY,
  GET_NEWS_ECOVACS_BY_CREATE_AT,
  GET_NEWS_ECOVACS_ID,
  GET_LIST_PRODUCT_ECOVACS,
  GET_PRODUCT_ECOVACS_ID,
  ADDRESS_NEWS_ECOVACS,
  GET_LIST_TT_BAOHANH,
  GET_CITY,
  GET_DISTRICT,
  GET_WARD,
  GET_PRODUCT_ERP,
  CHECK_BAO_HANH,
  ADD_BAO_HANH,
  TRA_CUU_BAO_HANH,
  DANH_SACH_SAN_PHAM_ECOVACS,
  DANH_SACH_PHU_KIEN_ECOVACS,
  GET_PRODUCTNAME,
  GET_TAOBAOHANHWEB,
  GET_TENSANPHAM,
  GET_DemDanhSachPhieuBaoHanh,
  GET_DanhSachPhieuBaoHanh,
  GET_YEUCAUDETAIL,
  GET_KICHHOATBAOHANH,
  GET_TRUNGTAMBAOHANH,
  GET_MADAILYBAN,
  UPLOAD_FILE,
  DOIMATKHAU,
  CAPNHAT_IMAGEBAOHANH,
  ADDFEEDBACK,
  GET_LOGIN,
  GET_THUONGHIEU,
} from "../type";

const initialState = {
  listCategory: [],
  listNewsEcovacs: [],
  newEcovasId: null,
  listProductEcovacs: [],
  productEcovacsId: null,
  listCityBaoHanh: [],
  listTTBaoHanh: [],

  listCity: [],
  listDistrict: [],
  listWard: [],
  listProductErp: [],
  hanBaoHanh: null,
  traCuuBaoHanh: null,
  listCity: [],
  listDistrict: [],
  listWard: [],
  listProductErp: [],
  hanBaoHanh: null,
  traCuuBaoHanh: null,

  danhSachSanPhamEcovacs: [],
  danhSachPhuKienEcovacs: [],
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      return {
        ...state,
        login: action.data,
      };
    case GET_THUONGHIEU:
      return {
        ...state,
        listThuongHieu: action.data,
      };
    case GET_PRODUCTNAME:
      return {
        ...state,
        getProductName: action.data,
      };

    case GET_CATEGORY:
      return {
        ...state,
        listCategory: action.data,
      };
    case GET_NEWS_ECOVACS_BY_CREATE_AT:
      return {
        ...state,
        listNewsEcovacs: action.data,
      };
    case GET_NEWS_ECOVACS_ID:
      return {
        ...state,
        newEcovasId: action.data,
      };
    case GET_LIST_PRODUCT_ECOVACS:
      return {
        ...state,
        listProductEcovacs: action.data,
      };
    case GET_PRODUCT_ECOVACS_ID:
      return {
        ...state,
        productEcovacsId: action.data,
      };
    case ADDRESS_NEWS_ECOVACS:
      return {
        ...state,
        listCityBaoHanh: action.data,
      };
    case GET_LIST_TT_BAOHANH:
      return {
        ...state,
        listTTBaoHanh: action.data,
      };
    case GET_CITY:
      return {
        ...state,
        listCity: action.data,
      };
    case GET_DISTRICT:
      return {
        ...state,
        listDistrict: action.data,
      };
    case GET_WARD:
      return {
        ...state,
        listWard: action.data,
      };
    case GET_PRODUCT_ERP:
      return {
        ...state,
        listProductErp: action.data,
      };
    case CHECK_BAO_HANH:
      return {
        ...state,
        hanBaoHanh: action.data,
      };
    case ADD_BAO_HANH:
      return {
        ...state,
      };
    case TRA_CUU_BAO_HANH:
      return {
        ...state,
        traCuuBaoHanh: action.data,
      };
    case DANH_SACH_SAN_PHAM_ECOVACS:
      return {
        ...state,
        danhSachSanPhamEcovacs: action.data,
      };
    case DANH_SACH_PHU_KIEN_ECOVACS:
      return {
        ...state,
        danhSachPhuKienEcovacs: action.data,
      };

    case GET_PRODUCTNAME:
      return {
        ...state,
        getProductName: action.data,
      };
    case GET_TAOBAOHANHWEB:
      return {
        ...state,
        getTaoBaoHanhWeb: action.data,
      };
    case GET_TENSANPHAM:
      return {
        ...state,
        getTenSanPham: action.data,
      };
    case GET_DemDanhSachPhieuBaoHanh:
      return {
        ...state,
        getDemDanhSachPhieuBaoHanh: action.data,
        loading: false,
      };
    case GET_DanhSachPhieuBaoHanh:
      return {
        ...state,
        getDanhSachPhieuBaoHanh: action.data,
        loading: false,
      };
    case GET_YEUCAUDETAIL:
      return {
        ...state,
        getYeuCauDetail: action.data,
      };

    case GET_KICHHOATBAOHANH:
      return {
        ...state,
        getKichHoatBaoHanh: action.data,
      };
    case GET_TRUNGTAMBAOHANH:
      return {
        ...state,
        getTrungTamBaoHanh: action.data,
      };
    case GET_MADAILYBAN:
      return {
        ...state,
        getMaDaiLyBan: action.data,
      };
    case GET_CITY:
      return {
        ...state,
        listCity: action.data,
      };
    case GET_DISTRICT:
      return {
        ...state,
        listDistrict: action.data,
      };
    case GET_WARD:
      return {
        ...state,
        listWard: action.data,
      };
    case GET_PRODUCT_ERP:
      return {
        ...state,
        listProductErp: action.data,
      };
    case CHECK_BAO_HANH:
      return {
        ...state,
        hanBaoHanh: action.data,
      };
    case ADD_BAO_HANH:
      return {
        ...state,
      };
    case TRA_CUU_BAO_HANH:
      return {
        ...state,
        traCuuBaoHanh: action.data,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        UploadFile: action.data,
      };
    case DOIMATKHAU:
      return {
        ...state,
        DoiMatKhau: action.data,
      };
    case CAPNHAT_IMAGEBAOHANH:
      return {
        ...state,
        updateImageBaoHanh: action.data,
      };

    case ADDFEEDBACK:
      return {
        ...state,
        AddFeedback: action.data,
      };

    default:
      return state;
  }
};

export default categories;
